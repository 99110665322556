import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLSection } from "models/sections";
import { GQLCategory } from "models/category";
import { GQLDataSection } from "models/dataSections";

import { DATA_SECTIONS_WITH_EXTRA_DATA_QUERY } from "graphql/queries/dataSections/dataSectionsWithExtraData";
import { Auth } from "utils/auth";
import { DataSections } from "components/DataSections";

const SectionsInfoContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <div>
      <Query query={DATA_SECTIONS_WITH_EXTRA_DATA_QUERY} clientUid={clientUid}>
        {({
          data: { categories, sections, dataSections },
          refetch,
        }: QueryResponse<GQLSection> &
          QueryResponse<GQLCategory> &
          QueryResponse<GQLDataSection>) => {
          return (
            <DataSections
              data={dataSections}
              categories={categories}
              sections={sections}
              refetch={refetch}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default SectionsInfoContainer;
