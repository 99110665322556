import gql from "graphql-tag";

// TODO: User clientUid to filter the order
export const ORDER_STATUS_UPDATE_MUTATION = gql`
  mutation UpdateOrderStatus(
    $orderId: ID!
    $status: ENUM_ORDERS_STATUS
    $lastUpdate: DateTime
  ) {
    updateOrder(
      input: {
        where: { id: $orderId }
        data: { status: $status, lastUpdate: $lastUpdate }
      }
    ) {
      order {
        id
      }
    }
  }
`;
