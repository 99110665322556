import gql from "graphql-tag";

const ROOMS_QUERY = gql`
  query Rooms($clientUid: String!) {
    rooms(where: { clientUid: $clientUid }) {
      id
      name
      floor
      description
      notDisturb
      laundry
    }
  }
`;

export default ROOMS_QUERY;
