import { GQLImage } from "./generic";

export interface GQLService {
  id: string;
  label: string;
  description: string;
  price: string;
  type: string;
  order: number;
  image: GQLImage;
}

export const SERVICES_TYPES = [
  { label: "Chat", value: "chat" },
  { label: "Order", value: "order" },
];
