import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";
import moment from "moment";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import Api from "api";
import {
  GUEST_CREATE_MUTATION,
  GUEST_UPDATE_MUTATION,
  GUEST_DELETE_MUTATION,
} from "graphql/mutations/guests";
import { Auth } from "utils/auth";
import { GQLGuest } from "models/guest";
import { GQLRoom } from "models/room";
import { GQLUser } from "models/user";
import { GQL_DATE_FORMAT, DATE_FORMAT } from "config/constants";

interface GuestsProps {
  data: GQLGuest[];
  rooms: GQLRoom[];
  refetch: any;
}

const Guests: React.FC<GuestsProps> = ({ data, rooms, refetch }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [checkIn, setCheckIn] = useState(new Date());
  const [checkOut, setCheckOut] = useState(new Date());
  const [roomId, setRoomId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(null);
  const [isActive, setIsActive] = useState(true);

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.guest && response.guest.id) {
      if (editId) {
        setMessage("The guest has been updated!");
      } else {
        setMessage("The guest has been created!");
      }
      setEditId(null);
      setError(false);
      refetch();
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the room!");
      } else {
        setMessage("There was an error creating the room!");
      }
    }
    setCheckIn(new Date());
    setCheckOut(new Date());
    setRoomId(null);
    setUserEmail("");
    setIsActive(true);
    setShowAdd(false);
    setUserName("");
  };

  const deleted = (response: any) => {
    if (response && response.guest && response.guest.id) {
      setError(false);
      setMessage("The guest has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the room!");
    }
    setEditId(null);
  };

  const upsertGuest = (mutation: () => {}) => async () => {
    let error = false;
    let localUserId;
    setError(false);
    setMessage("");
    if (!editId) {
      try {
        const users: GQLUser[] = (await Api.findUserByEmail(userEmail)) as any;
        if (users.length === 1) {
          localUserId = users[0].id;
          setUserId(localUserId);
        }
      } catch (err) {
        error = true;
      }

      if (!error && !localUserId) {
        try {
          const newUser: GQLUser = (await Api.createNewUser(
            userEmail,
            userName
          )) as any;

          if (newUser) {
            localUserId = newUser.id;
            setUserId(localUserId);
          }
        } catch (err) {
          console.error(err);
          error = true;
        }
      }
    }

    if (editId || (!error && localUserId)) {
      mutation();
    } else {
      setMessage("We couldn't find or create the user");
      setError(true);
      setCheckIn(new Date());
      setCheckOut(new Date());
      setRoomId(null);
      setUserEmail("");
      setIsActive(true);
      setShowAdd(false);
      setUserName("");
    }
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={GUEST_DELETE_MUTATION}
        variables={{
          guestId: rowData.id,
        }}
        onCompleted={({ deleteGuest }: any) => {
          deleted(deleteGuest);
        }}
      >
        {(mutation: () => {}) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setCheckIn(moment(rowData.checkIn, GQL_DATE_FORMAT).toDate());
          setCheckOut(moment(rowData.checkOut, GQL_DATE_FORMAT).toDate());
          setIsActive(rowData.active);
          setRoomId((rowData.room && rowData.room.id) || null);
          setShowAdd(true);
          setUserEmail(rowData.user.email);
        }}
      ></Button>
    );
  };

  const booleanColumn = (rowData: any, column: any) => {
    return (
      <ToggleButton
        checked={rowData[column.field]}
        style={{ pointerEvents: "none" }}
      />
    );
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? GUEST_UPDATE_MUTATION : GUEST_CREATE_MUTATION}
        variables={{
          guestId: editId,
          clientId,
          clientUid,
          roomId,
          userId,
          checkIn: moment(checkIn).format(GQL_DATE_FORMAT),
          checkOut: moment(checkOut).format(GQL_DATE_FORMAT),
          isActive,
        }}
        onCompleted={({ createGuest, updateGuest }: any) => {
          upserted(createGuest || updateGuest);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={upsertGuest(mutation)}
          />
        )}
      </Mutation>
    );
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Guest email:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Email"
              value={userEmail}
              readOnly={!!editId}
              onChange={(e: any) => setUserEmail(e.target.value)}
            />
          </div>
          {!editId ? (
            <>
              <div className="p-col-12">Guest full name:</div>
              <div className="p-col-12">
                <InputText
                  placeholder="Full name"
                  value={userName}
                  onChange={(e: any) => setUserName(e.target.value)}
                />
              </div>
            </>
          ) : null}
          <div className="p-col-12">Check In:</div>
          <div className="p-col-12">
            <Calendar
              value={checkIn}
              dateFormat="dd-mm-yy"
              onChange={(e) => setCheckIn(e.value as Date)}
            ></Calendar>
          </div>
          <div className="p-col-12">Check Out:</div>
          <div className="p-col-12">
            <Calendar
              value={checkOut}
              dateFormat="dd-mm-yy"
              onChange={(e) => setCheckOut(e.value as Date)}
            ></Calendar>
          </div>
          <div className="p-col-12">Room:</div>
          <div className="p-col-12">
            <Dropdown
              value={roomId}
              optionLabel="name"
              optionValue="id"
              options={rooms}
              onChange={(e) => {
                setRoomId(e.value);
              }}
              placeholder="Select a Section"
            />
          </div>
          {editId ? (
            <>
              <div className="p-col-12">Is Active:</div>
              <div className="p-col-12">
                <ToggleButton
                  checked={isActive}
                  onChange={(e: any) => {
                    setIsActive(e.value);
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      </Dialog>
      <div className="card card-w-title">
        <DataTable value={data} header="Rooms">
          <Column
            field="user"
            header="Name"
            sortable={true}
            body={({ user }) => (user ? user.name || user.email : "")}
          />
          <Column
            field="checkIn"
            header="CheckIn"
            sortable={true}
            body={(rowData: any) =>
              moment(rowData.checkIn, GQL_DATE_FORMAT).format(DATE_FORMAT)
            }
          />
          <Column
            field="checkOut"
            header="CheckOut"
            sortable={true}
            body={(rowData: any) =>
              moment(rowData.checkOut, GQL_DATE_FORMAT).format(DATE_FORMAT)
            }
          />
          <Column
            field="room"
            header="Room"
            sortable={true}
            body={({ room }) => (room ? room.name : "")}
          />
          <Column
            field="active"
            header="Is Active"
            body={booleanColumn}
            sortable={true}
          />
          <Column field="id" header="Edit" sortable={false} body={editColumn} />
          <Column
            field="id"
            header="Delete"
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};

export default Guests;
