import React from "react";

import ChatContainer from "../../containers/Chat";

export const PageChat = () => {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h1>Chat</h1>
          <ChatContainer />
        </div>
      </div>
    </div>
  );
};
