import React from "react";

import ViewRooms from "components/ViewRooms";
import RoomsContainer from "../../containers/Rooms";

export const PageViewRooms = () => {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h1>Rooms</h1>
          <RoomsContainer Component={ViewRooms} />
        </div>
      </div>
    </div>
  );
};
