import React from "react";

import Query from "components/Query";
import Guests from "components/Guests";
import GUESTS_QUERY from "../../graphql/queries/guests/guests";
import { QueryResponse } from "../../models/query";
import { GQLGuest } from "../../models/guest";
import { Auth } from "utils/auth";
import { GQLRoom } from "models/room";

const GuestsContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <div>
      <Query query={GUESTS_QUERY} clientUid={clientUid}>
        {({
          data: { guests, rooms },
          refetch,
        }: QueryResponse<GQLGuest> & QueryResponse<GQLRoom>) => {
          return <Guests data={guests} rooms={rooms} refetch={refetch} />;
        }}
      </Query>
    </div>
  );
};

export default GuestsContainer;
