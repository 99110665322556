export interface GQLImage {
  url: string;
}

export interface GQLFile {
  url: string;
}

export const ROLES = ["Admin", "Employee", "Maintainer"];

export interface GQLRole {
  id: string;
  name: string;
}
