import React, { useState } from "react";
import Axios from "axios";
import { GQLBranding } from "models/branding";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { FileUpload } from "primereact/fileupload";

import {
  BRANDING_CREATE_MUTATION,
  BRANDING_UPDATE_MUTATION,
} from "graphql/mutations/branding";
import { Auth } from "utils/auth";
import Api from "api";

interface BrandingProps {
  data: GQLBranding;
  refetch: any;
}

export const Branding: React.FC<BrandingProps> = ({ data, refetch }) => {
  const {
    id: originalId,
    title: originalTitle,
    customDomain: originalCustomDomain,
    primaryColor: originalPrimaryColor,
    secondaryColor: originalSecondaryColor,
    primaryText: originalPrimaryText,
    secondaryText: originalSecondaryText,
    logo: originalLogo,
  } = data || ({} as GQLBranding);

  const [brandingId, setBrandingId] = useState(originalId || null);
  const [title, setTitle] = useState(originalTitle || "");
  const [customDomain, setCustomDomain] = useState(originalCustomDomain || "");
  const [primaryColor, setPrimaryColor] = useState(originalPrimaryColor);
  const [secondaryColor, setSecondaryColor] = useState(originalSecondaryColor);
  const [primaryText, setPrimaryText] = useState(originalPrimaryText);
  const [secondaryText, setSecondaryText] = useState(originalSecondaryText);
  const [currentImage, setCurrentImage] = useState(
    originalLogo ? originalLogo.url : ""
  );
  const [pendingFiles, setPendingFiles] = useState<any>(null);
  const [logo] = useState(originalLogo);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();
  const updated = (createBranding: any) => {
    if (
      createBranding &&
      createBranding.branding &&
      createBranding.branding.id
    ) {
      setBrandingId(createBranding.branding.id);
      setMessage("The branding has been updated!");

      if (pendingFiles) {
        const formData = new FormData();
        formData.set("ref", "branding");
        formData.set("field", "logo");
        const file = pendingFiles[0];
        formData.append("files", file, file.name);
        formData.set("refId", createBranding.branding.id);
        const token = Auth.getToken();
        Axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token ? `Bearer ${token}` : "",
          },
        })
          .catch((response: any) => {
            console.log(response);
          })
          .finally(async () => {
            refetch();
            try {
              const image = await Api.findBrandImage(clientUid);
              setCurrentImage(image as string);
            } catch (err) {
              console.log("Error reading the new image", err);
            }
            setPendingFiles(null);
          });
      } else {
        refetch();
        setError(false);
      }
    } else {
      setError(true);
      setMessage("There was an error updating your brand!");
    }
  };
  const onChange = (setFunction: any) => (e: any) => {
    setFunction(e.target.value);
  };

  const setFormData = (event: any) => {
    setPendingFiles(event.files);
  };

  return (
    <>
      <div className="p-grid">
        {message.length ? (
          <div
            className={classnames(
              "p-messages",
              "p-component",
              "p-col-12",
              error ? "p-messages-error" : "p-messages-success"
            )}
          >
            <div className="p-messages-wrapper">
              <ul>
                <li>
                  <span className="p-messages-detail">{message}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <h3 className="p-col-12">Name &amp; Domain</h3>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="title">Title</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputText id="title" onChange={onChange(setTitle)} value={title} />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="customDomain">Custom Domain</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputText
              id="customDomain"
              onChange={onChange(setCustomDomain)}
              value={customDomain}
            />
          </div>
        </div>
        <h3 className="p-col-12">Color Schema</h3>
        <div className="p-col-12 p-md-2">
          <div>Primary color</div>
          <ColorPicker
            value={primaryColor}
            style={{
              width: "32px",
            }}
            onChange={(e) => setPrimaryColor(e.value)}
          />
          <div>Selected: {primaryColor}</div>
        </div>
        <div className="p-col-12 p-md-3">
          <div>Secondary color</div>
          <ColorPicker
            value={secondaryColor}
            style={{
              width: "32px",
            }}
            onChange={(e) => setSecondaryColor(e.value)}
          />
          <div>Selected: {secondaryColor}</div>
        </div>
        <div className="p-col-12 p-md-3">
          <div>Primary text color</div>
          <ColorPicker
            style={{
              width: "32px",
            }}
            value={primaryText}
            onChange={(e) => setPrimaryText(e.value)}
          />
          <div>Selected: {primaryText}</div>
        </div>
        <div className="p-col-12 p-md-3">
          <div>Secondary text color</div>
          <ColorPicker
            value={secondaryText}
            style={{
              width: "32px",
            }}
            onChange={(e) => setSecondaryText(e.value)}
          />
          <div>Selected: {secondaryText}</div>
        </div>
        <h3 className="p-col-12">Your logo</h3>
        <div className="p-col-12 p-md-2">
          <label htmlFor="input">Logo</label>
        </div>
        {logo && logo.url ? (
          <div className="p-col-12 p-md-2">
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}${currentImage}`}
              width="100%"
              height="auto"
              alt=""
            />
          </div>
        ) : null}
        <div className="p-col-12 p-md-4">
          <FileUpload
            name="logo"
            url="./-"
            accept="image/*"
            mode="basic"
            auto={true}
            onError={setFormData}
          />
        </div>
        <div className="p-col-12">
          <div className="p-col-3">
            <Mutation
              mutation={
                brandingId ? BRANDING_UPDATE_MUTATION : BRANDING_CREATE_MUTATION
              }
              variables={{
                title,
                customDomain,
                primaryColor,
                primaryText,
                secondaryColor,
                secondaryText,
                clientId,
                clientUid,
                brandingId,
              }}
              onCompleted={({ createBranding, updateBranding }: any) =>
                updated(createBranding || updateBranding)
              }
            >
              {(mutation: any) => (
                <Button
                  label="Save"
                  icon="pi pi-pencil"
                  onClick={() => {
                    mutation();
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </>
  );
};
