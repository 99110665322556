import React from "react";

export const AppFooter = () => (
  <div className="layout-footer">
    <img
      src={`${process.env.PUBLIC_URL}/images/logo_black.svg`}
      alt=""
      width="100"
    />
    <span
      className="footer-text"
      style={{
        marginLeft: "5px",
        borderLeft: "1px solid #000",
        paddingLeft: "15px",
        fontSize: "10px",
      }}
    >
      Developed by Klivr
    </span>
  </div>
);
