import React from "react";
import { useMutation } from "react-apollo";
import moment from "moment";

import { Dropdown } from "primereact/dropdown";

import { Auth } from "utils/auth";
import { ORDER_STATUS_UPDATE_MUTATION } from "graphql/mutations/orders";
import { ORDER_STATUSES } from "models/orders";
import Api from "api";

interface DropdownStatusProps {
  orderId: string;
  guestId: string;
  status: string;
  refetch: any;
}

export default ({ orderId, guestId, status, refetch }: DropdownStatusProps) => {
  const [updateStatus] = useMutation(ORDER_STATUS_UPDATE_MUTATION, {
    onCompleted: () => {
      refetch();
      Api.sendNotification(guestId, "orders");
    },
  });

  const clientUid = Auth.getClientUid();

  return (
    <Dropdown
      options={ORDER_STATUSES}
      value={status}
      onChange={(e) =>
        updateStatus({
          variables: {
            clientUid,
            orderId,
            status: e.value,
            lastUpdate: moment().format(),
          },
        })
      }
    />
  );
};
