import React, { useState, useEffect } from "react";
import { Auth } from "utils/auth";
import { NavLink } from "react-router-dom";
import Api from "api";

interface AppTopBarProps {
  onToggleMenu: any;
}

export const AppTopBar: React.FC<AppTopBarProps> = ({ onToggleMenu }) => {
  const [notifications, setNotifications] = useState([]);
  const [reading, setReading] = useState(false);

  useEffect(() => {
    const notificationsPolling = setInterval(async () => {
      if (!reading) {
        try {
          const newNotifications = (await Api.getNewNotifications()) as any[];
          setNotifications(newNotifications);
        } catch (error) {
          console.error("Notif: ", error);
          setNotifications([]);
        }
      }
    }, 5000);

    return () => clearInterval(notificationsPolling);
  }, [reading, setNotifications]);

  const readNotifications = () => {
    setReading(true);

    notifications.forEach((notification) => {
      Api.markAsRead(notification.id);
    });

    setReading(false);
  };

  return (
    <div className="layout-topbar clearfix">
      {Auth.isAuthenticated() ? (
        <>
          <button className="p-link layout-menu-button" onClick={onToggleMenu}>
            <span className="pi pi-bars" />
          </button>
          <div className="layout-topbar-icons">
            <button className="p-link" onClick={readNotifications}>
              <span className="layout-topbar-item-text">Notifications</span>
              <span className="layout-topbar-icon pi pi-bell" />
              <span className="layout-topbar-badge">
                {notifications.length}
              </span>
            </button>
            <NavLink to="/my-account">
              <button className="p-link">
                <span className="layout-topbar-item-text">User</span>
                <span className="layout-topbar-icon pi pi-user" />
              </button>
            </NavLink>
          </div>
        </>
      ) : null}
    </div>
  );
};
