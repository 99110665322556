import gql from "graphql-tag";

const USERS_QUERY = gql`
  query Users($clientUid: String!) {
    users(where: { clientUid: $clientUid }) {
      id
      name
      username
      email
      role {
        id
        name
      }
      blocked
    }

    roles {
      id
      name
    }
  }
`;

export default USERS_QUERY;
