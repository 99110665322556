import gql from "graphql-tag";

export const SETTINGS_QUERY = gql`
  query Settings($clientUid: String!) {
    settings(where: { clientUid: $clientUid }) {
      id
      checkIn
      services
      roomService
    }
  }
`;
