import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLService } from "models/services";

import { SERVICES_QUERY } from "graphql/queries/services/services";
import { Auth } from "utils/auth";
import { Services } from "components/Services";

const SectionsContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <div>
      <Query query={SERVICES_QUERY} clientUid={clientUid}>
        {({ data: { services }, refetch }: QueryResponse<GQLService>) => {
          return <Services data={services} refetch={refetch} />;
        }}
      </Query>
    </div>
  );
};

export default SectionsContainer;
