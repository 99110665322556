import gql from "graphql-tag";

const GUESTS_QUERY = gql`
  query Guests($clientUid: String!) {
    guests(where: { clientUid: $clientUid }, sort: "active:asc") {
      id
      room {
        id
        name
      }
      user {
        id
        email
        name
      }
      chatRoom
      checkIn
      checkOut
      active
    }

    rooms(where: { clientUid: $clientUid }) {
      id
      name
    }
  }
`;

export default GUESTS_QUERY;
