import React from "react";

import CheckInConfigContainer from "../../containers/CheckInConfig";

export const PageCheckInConfig = () => {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h1>Check In</h1>
          <CheckInConfigContainer />
        </div>
      </div>
    </div>
  );
};
