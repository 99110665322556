import gql from "graphql-tag";

export const BRANDING_QUERY = gql`
  query Branding($clientUid: String!) {
    brandings(where: { clientUid: $clientUid }) {
      id
      title
      primaryColor
      secondaryColor
      primaryText
      secondaryText
      customDomain
      logo {
        url
      }
    }
  }
`;
