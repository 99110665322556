import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";

import { Auth } from "utils/auth";
import { ORDERS_QUERY } from "graphql/queries/orders/orders";
import { GQLOrder } from "models/orders";
import { GQLService } from "models/services";
import Orders from "components/Orders";

const OrdersContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={ORDERS_QUERY} clientUid={clientUid}>
      {({
        data: { orders, services },
        refetch,
      }: QueryResponse<GQLOrder> & QueryResponse<GQLService>) => {
        return <Orders data={orders} services={services} refetch={refetch} />;
      }}
    </Query>
  );
};

export default OrdersContainer;
