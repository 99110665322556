import React, { useState } from "react";
import Axios from "axios";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

import {
  SERVICES_CREATE_MUTATION,
  SERVICES_UPDATE_MUTATION,
  SERVICES_DELETE_MUTATION,
} from "graphql/mutations/services";
import { Auth } from "utils/auth";
import { GQLService, SERVICES_TYPES } from "models/services";

interface ServicesProps {
  data: GQLService[];
  refetch: any;
}

export const Services: React.FC<ServicesProps> = ({ data, refetch }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState("order");
  const [order, setOrder] = useState(0);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [pendingFiles, setPendingFiles] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.service && response.service.id) {
      setError(false);
      if (editId) {
        setMessage("The service has been updated!");
      } else {
        setMessage("The service has been created!");
      }

      if (pendingFiles) {
        const formData = new FormData();
        formData.set("ref", "services");
        formData.set("field", "image");
        const file = pendingFiles[0];
        formData.append("files", file, file.label);
        formData.set("refId", response.service.id);
        const token = Auth.getToken();
        Axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token ? `Bearer ${token}` : "",
          },
        })
          .then(() => {
            refetch();
          })
          .catch((response: any) => {
            console.log(response);
          })
          .finally(() => {
            setPendingFiles(null);
          });
      } else {
        refetch();
      }
      setEditId(null);
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the service!");
      } else {
        setMessage("There was an error creating the service!");
      }
    }
    setLabel("");
    setDescription("");
    setPrice("");
    setType("order");
    setOrder(0);
    setCurrentPhoto(null);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.service && response.service.id) {
      setError(false);
      setMessage("The service has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the service!");
    }
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={SERVICES_DELETE_MUTATION}
        variables={{
          serviceId: rowData.id,
        }}
        onCompleted={({ deleteService }: any) => {
          deleted(deleteService);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setLabel(rowData.label);
          setDescription(rowData.description);
          setPrice(rowData.price);
          setType(rowData.type);
          setOrder(rowData.order);
          setShowAdd(true);
          setCurrentPhoto(rowData.image ? rowData.image.url : null);
        }}
      ></Button>
    );
  };

  const imageColumn = (rowData: any, column: any) => {
    return rowData[column.field] && rowData[column.field].url ? (
      <img
        src={`${process.env.REACT_APP_BACKEND_URL}${rowData[column.field].url}`}
        width="100%"
        height="auto"
        alt=""
      />
    ) : null;
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? SERVICES_UPDATE_MUTATION : SERVICES_CREATE_MUTATION}
        variables={{
          clientId,
          clientUid,
          serviceId: editId,
          label,
          description,
          price,
          type,
          order,
        }}
        onCompleted={({ createService, updateService }: any) => {
          upserted(createService || updateService);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const setFormData = (event: any) => {
    setPendingFiles(event.files);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Label:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Descriptive title"
              value={label}
              onChange={(e: any) => setLabel(e.target.value)}
            />
          </div>
          <div className="p-col-12">Price:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Price"
              value={price}
              onChange={(e: any) => setPrice(e.target.value)}
            />
          </div>
          <div className="p-col-12">Description:</div>
          <div className="p-col-12">
            <InputTextarea
              rows={4}
              cols={60}
              placeholder="Description"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              autoResize={true}
            />
          </div>
          <div className="p-col-12">Type:</div>
          <div className="p-col-12">
            <Dropdown
              placeholder="Type"
              value={type}
              options={SERVICES_TYPES}
              onChange={(e: any) => setType(e.value)}
            />
          </div>
          <div className="p-col-12">Order:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
          <div className="p-col-12">Image:</div>
          {currentPhoto ? (
            <div className="p-col-12">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${currentPhoto}`}
                width="100%"
                height="auto"
                alt=""
              />
            </div>
          ) : null}
          <div className="p-col-12">
            <FileUpload
              name="logo"
              url="./upload"
              accept="image/*"
              mode="basic"
              auto={true}
              onError={setFormData}
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <h1>Services</h1>
        <DataTable
          value={data}
          header="Services"
          style={{ textAlign: "center" }}
          paginator={true}
          rows={10}
        >
          <Column field="label" header="Label" sortable={true} />
          <Column field="price" header="Price" sortable={true} />
          <Column field="description" header="Description" />
          <Column field="type" header="Type" />
          <Column field="order" header="Order" sortable={true} />
          <Column
            field="image"
            header="Image"
            sortable={false}
            body={imageColumn}
          />
          <Column field="id" header="Edit" sortable={false} body={editColumn} />
          <Column
            field="id"
            header="Delete"
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
