import gql from "graphql-tag";

const CHECK_IN_CONFIG_QUERY = gql`
  query CheckIn($clientUid: String!) {
    checkInConfigs(where: { clientUid: $clientUid }) {
      id
      name
      label
      description
      type
      order
      required
    }
  }
`;

export default CHECK_IN_CONFIG_QUERY;
