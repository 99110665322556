import React from "react";

import Query from "components/Query";

import { DASHBOARD_QUERY } from "graphql/queries/dashboard";
import { GQLDashboard } from "models/dashboard";
import { Auth } from "utils/auth";
import { Dashboard } from "components/Dashboard";
import { QueryResponseCustom } from "models/query";

const DashboardContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={DASHBOARD_QUERY} clientUid={clientUid}>
      {({ data, refetch }: QueryResponseCustom<GQLDashboard>) => {
        return <Dashboard data={data} refetch={refetch} />;
      }}
    </Query>
  );
};

export default DashboardContainer;
