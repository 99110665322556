import React, { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

const data = [
  {
    room: "105",
    datetime: "22:01PM",
    type: "Room Service",
    message: "Sandwich Ham&Cheese",
  },
  {
    room: "321",
    datetime: "22:01PM",
    type: "Room Service",
    message: "Mobile phone to use",
  },
  {
    room: "106",
    datetime: "22:00PM",
    type: "Message",
    message: "Hello, can I extends the boo...",
  },
  {
    room: "208",
    datetime: "22:00PM",
    type: "Services",
    message: "Late checkout",
  },
];

export const PageNotifications = () => {
  const [tableState, setTableState] = useState({
    sortOrder: 1,
    sortField: "",
    sortKey: "",
    data,
    sortOptions: [
      { label: "Newest First", value: "!year" },
      { label: "Oldest First", value: "year" },
      { label: "Brand", value: "brand" },
    ],
    dataTableSelection: "",
  });

  const onSelectRow = ({ value }: any) =>
    setTableState({
      ...tableState,
      dataTableSelection: value,
    });

  const header = (
    <div className="p-grid">
      <div className="p-col-1 p-md-1">
        <InputText
          placeholder="Search by room"
          onKeyUp={(event: any) => {
            setTableState({
              ...tableState,
              data: data.filter(({ room }: any) =>
                room.includes(event.currentTarget.value)
              ),
            });
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h1>Notifications</h1>
          <DataTable
            value={tableState.data}
            paginatorPosition="both"
            sortMode="single"
            header={header}
            paginator={true}
            rows={10}
            responsive={true}
            selection={tableState.dataTableSelection}
            onSelectionChange={onSelectRow}
          >
            <Column field="room" header="Room" sortable={true} />
            <Column field="datetime" header="Time" sortable={true} />
            <Column field="type" header="Type" sortable={true} />
            <Column field="message" header="Message" sortable={true} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
