import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLBranding } from "models/branding";

import { BRANDING_QUERY } from "graphql/queries/branding/branding";
import { Auth } from "utils/auth";
import { Branding } from "components/Branding";

const BrandingContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={BRANDING_QUERY} clientUid={clientUid}>
      {({ data: { brandings }, refetch }: QueryResponse<GQLBranding>) => {
        return (
          <Branding
            data={(brandings || [{} as GQLBranding])[0]}
            refetch={refetch}
          />
        );
      }}
    </Query>
  );
};

export default BrandingContainer;
