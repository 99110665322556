import React from "react";

import SectionsInfoContainer from "containers/SectionsInfo";

export const PageSectionsInfo: React.FC = () => {
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>Sections Info Setup</h1>
            <SectionsInfoContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
