import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { GQLUser } from "models/user";
import { Password } from "primereact/password";
import {
  MY_ACCOUNT_PASSWORD_UPDATE_MUTATION,
  MY_ACCOUNT_UPDATE_MUTATION,
} from "graphql/mutations/myAccount";

interface MyAccountProps {
  data: GQLUser;
  refetch: any;
}

export const MyAccount: React.FC<MyAccountProps> = ({ data, refetch }) => {
  const {
    id: originalId,
    name: originalName,
    username: originalUsername,
    email: originalEmail,
  } = data;

  const [userId, setUserId] = useState(originalId || null);
  const [name, setName] = useState(originalName || "");
  const [username, setUsername] = useState(originalUsername || "");
  const [email, setEmail] = useState(originalEmail || "");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const updated = (response: any) => {
    if (response && response.user && response.user.id) {
      setUserId(response.user.id);
      setMessage("The user has been updated!");

      refetch();
      setError(false);
    } else {
      setError(true);
      setMessage("There was an error updating your user!");
    }
  };
  const onChange = (setFunction: any) => (e: any) => {
    setFunction(e.target.value);
  };

  return (
    <>
      <div className="p-grid">
        {message.length ? (
          <div
            className={classnames(
              "p-messages",
              "p-component",
              "p-col-12",
              error ? "p-messages-error" : "p-messages-success"
            )}
          >
            <div className="p-messages-wrapper">
              <ul>
                <li>
                  <span className="p-messages-detail">{message}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <h3 className="p-col-12">Account Details</h3>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="name">Name</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputText id="name" onChange={onChange(setName)} value={name} />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="username">User Name</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputText
              id="username"
              onChange={onChange(setUsername)}
              value={username}
            />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="email">Email</label>
          </div>
          <div className="p-col-12 p-md-4">
            <InputText id="email" onChange={onChange(setEmail)} value={email} />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-12 p-md-2">
            <label htmlFor="password">
              Password (Only if you want to change it)
            </label>
          </div>
          <div className="p-col-12 p-md-4">
            <Password
              id="password"
              onChange={onChange(setPassword)}
              value={password}
            />
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-col-3">
            <Mutation
              mutation={
                password.length
                  ? MY_ACCOUNT_PASSWORD_UPDATE_MUTATION
                  : MY_ACCOUNT_UPDATE_MUTATION
              }
              variables={{
                name,
                username,
                email,
                userId,
                password,
              }}
              onCompleted={({ updateUser }: any) => updated(updateUser)}
            >
              {(mutation: any) => (
                <Button
                  label="Save"
                  icon="pi pi-pencil"
                  onClick={() => {
                    mutation();
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </>
  );
};
