import {
  AUTH_TOKEN,
  STORAGE_ROLE,
  STORAGE_USER_ID,
  STORAGE_CLIENT_ID,
  STORAGE_CLIENT_UID,
} from "config/constants";
import Axios from "axios";

export const Auth = {
  isAuthenticated: () => {
    const token = localStorage.getItem(AUTH_TOKEN) || "";
    return !!token.length;
  },
  getToken: () => {
    return localStorage.getItem(AUTH_TOKEN) || "";
  },
  getUserId: () => {
    return localStorage.getItem(STORAGE_USER_ID) || "";
  },
  getClientId: () => {
    return localStorage.getItem(STORAGE_CLIENT_ID) || "";
  },
  getClientUid: () => {
    return localStorage.getItem(STORAGE_CLIENT_UID) || "";
  },
  getRole: () => {
    return localStorage.getItem(STORAGE_ROLE) || "";
  },
  authenticate: async (token: string, role: string, userId: string) => {
    const {
      data: user,
    }: any = await Axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (user.clientUid) {
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(STORAGE_ROLE, role);
      localStorage.setItem(STORAGE_CLIENT_ID, user.client.id);
      localStorage.setItem(STORAGE_CLIENT_UID, user.clientUid);
      localStorage.setItem(STORAGE_USER_ID, userId);
    } else {
      throw new Error("User is not assigned to any client");
    }
  },
  clear: () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(STORAGE_CLIENT_ID);
    localStorage.removeItem(STORAGE_CLIENT_UID);
    localStorage.removeItem(STORAGE_ROLE);
    localStorage.removeItem(STORAGE_USER_ID);
  },
};
