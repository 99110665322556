import { Auth } from "utils/auth";

interface IMenuOption {
  label: string;
  icon: string;
  badge?: number;
  command?: () => any;
  to?: string;
  items?: IMenuOption[];
  refresh?: boolean;
}

export type IMenu = IMenuOption[];

export const MAIN_MENU_OPTIONS: IMenu = [
  {
    label: "Dashboard",
    icon: "pi pi-fw pi-home",
    to: "/",
  },
  {
    label: "Orders",
    // badge: 8,
    icon: "pi pi-fw pi-inbox",
    to: "/orders",
  },
  {
    label: "Rooms",
    icon: "pi pi-fw pi-tags",
    to: "/rooms/",
  },
  {
    label: "Guests",
    icon: "pi pi-fw pi-users",
    to: "/guests/",
  },
  {
    label: "Chat",
    icon: "pi pi-fw pi-comments",
    to: "/chat",
  },
  {
    label: "Setup",
    icon: "pi pi-fw pi-cog",
    items: [
      {
        label: "General Settings",
        icon: "pi pi-fw pi-sliders-h",
        to: "/setup/settings",
      },
      {
        label: "Branding",
        icon: "pi pi-fw pi-palette",
        to: "/setup/branding",
      },
      {
        label: "Check in",
        icon: "pi pi-fw pi-id-card",
        to: "/setup/checkin/",
      },
      {
        label: "Rooms",
        icon: "pi pi-fw pi-tags",
        to: "/setup/rooms/",
      },
      {
        label: "Dynamic Modules",
        icon: "pi pi-fw pi-info",
        items: [
          {
            label: "Sections",
            icon: "pi pi-fw pi-table",
            to: "/setup/sections",
          },
          {
            label: "Categories",
            icon: "pi pi-fw pi-table",
            to: "/setup/categories",
          },
          {
            label: "Information",
            icon: "pi pi-fw pi-info-circle",
            to: "/setup/sectionsinfo",
          },
        ],
      },
      {
        label: "Services",
        icon: "pi pi-fw pi-star",
        to: "/setup/services/",
      },
      {
        label: "Users",
        icon: "pi pi-fw pi-users",
        to: "/setup/users/",
      },
      {
        // To enable OTP Device and Enable/Disable apps
        label: "Apps & Security",
        icon: "pi pi-fw pi-lock",
        to: "/setup/security/",
      },
      {
        label: "Integrations (Advanced)",
        icon: "pi pi-fw pi-cloud",
        items: [
          {
            label: "Outgoing",
            icon: "pi pi-fw pi-cloud-upload",
            to: "/setup/integrations/outgoing",
          },
          {
            label: "Incoming",
            icon: "pi pi-fw pi-cloud-download",
            to: "/setup/integrations/incoming",
          },
        ],
      },
    ],
  },
  {
    label: "Experiences",
    icon: "pi pi-fw pi-tags",
    items: [
      {
        label: "Promotions",
        icon: "pi pi-fw pi-ticket",
        to: "/promotions/",
      },
      {
        label: "Activities",
        icon: "pi pi-fw pi-camera",
        to: "/activities/",
      },
      {
        label: "Recomendations",
        icon: "pi pi-fw pi-thumbs-up",
        to: "/recomendations/",
      },
      {
        label: "Warnings",
        icon: "pi pi-fw pi-exclamation-circle",
        to: "/warnings/",
      },
    ],
  },
  {
    label: "Help",
    icon: "pi pi-fw pi-question",
    to: "/help",
  },
  {
    label: "Logout",
    icon: "pi pi-fw pi-sign-out",
    command: () => {
      Auth.clear();
      window.location.href = "/";
    },
  },
];
