import gql from "graphql-tag";

export const DATA_SECTIONS_CREATE_MUTATION = gql`
  mutation CreateDataSection(
    $clientId: ID!
    $clientUid: String!
    $title: String!
    $description: String
    $subDescription: String
    $sectionId: ID
    $categoryId: ID
    $order: Int
  ) {
    createDataSection(
      input: {
        data: {
          title: $title
          description: $description
          subDescription: $subDescription
          client: $clientId
          clientUid: $clientUid
          section: $sectionId
          category: $categoryId
          order: $order
        }
      }
    ) {
      dataSection {
        id
      }
    }
  }
`;

export const DATA_SECTIONS_UPDATE_MUTATION = gql`
  mutation UpdateDataSection(
    $dataSectionId: ID!
    $title: String!
    $description: String
    $subDescription: String
    $sectionId: ID
    $categoryId: ID
    $order: Int
  ) {
    updateDataSection(
      input: {
        where: { id: $dataSectionId }
        data: {
          title: $title
          description: $description
          subDescription: $subDescription
          section: $sectionId
          category: $categoryId
          order: $order
        }
      }
    ) {
      dataSection {
        id
      }
    }
  }
`;

export const DATA_SECTIONS_DELETE_MUTATION = gql`
  mutation DeleteDataSection($dataSectionId: ID!) {
    deleteDataSection(input: { where: { id: $dataSectionId } }) {
      dataSection {
        id
      }
    }
  }
`;
