import React, { useState, useEffect } from "react";
import moment from "moment";
import classnames from "classnames";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { GQLGuest } from "models/guest";

import { Auth } from "utils/auth";

import { fbDatabase } from "config/firebase";

import "./Chat.scss";

interface ChatProps {
  data: GQLGuest[];
  refetch: any;
}

export const Chat: React.FC<ChatProps> = ({ data, refetch }) => {
  const [currentChatRoom, setCurrentChatRoom] = useState(null);
  const [currentGuestId, setCurrentGuestId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [listMessages, setListMessages] = useState([]);

  useEffect(() => {
    if (currentChatRoom) {
      const messagesRef = fbDatabase.ref(currentChatRoom);
      messagesRef.on("value", (snapshot) => {
        let messagesObj = snapshot.val();
        if (messagesObj) {
          let messages = [];
          Object.keys(messagesObj).forEach((key) =>
            messages.push(messagesObj[key])
          );
          setListMessages(messages);
        }
      });
    }
  }, [currentChatRoom, setListMessages]);

  const clientUid = Auth.getClientUid();

  const onStartChat = (guestId: string, chatRoom: string) => {
    const currentChatRoom = `rooms/${chatRoom}/messages`;
    setCurrentChatRoom(currentChatRoom);
    setCurrentGuestId(guestId);
  };

  const onChangeMessage = (e: any) => setNewMessage(e.target.value);
  const onSendMessage = () => {
    const timestamp = moment().valueOf();
    const itemMessage = {
      idFrom: clientUid,
      idTo: currentGuestId,
      timestamp: timestamp,
      content: newMessage.trim(),
    };
    fbDatabase.ref(currentChatRoom).push(itemMessage);
    setNewMessage("");
  };

  const onKeyInputMessageDown = (e: any) => {
    if (e.key === "Enter") {
      onSendMessage();
    }
  };

  return (
    <div className="p-grid chat">
      <div className="p-col-3 guests">
        {data.map(({ id, room, user, chatRoom }: GQLGuest) => {
          return (
            <div
              className={classnames("p-col-12 p-md-2 guest", {
                talking: id === currentGuestId,
              })}
              key={id}
              onClick={() => onStartChat(id, chatRoom)}
            >
              <span className="chat-room">Room: {room.name}</span>
              <span className="chat-guest">
                Guest: {user.name || user.email}
              </span>
            </div>
          );
        })}
      </div>
      <div className="p-col-9 chat-window">
        {!currentChatRoom ? (
          <div className="no-chat">Select one Guest to talk with</div>
        ) : (
          <>
            <div className="messages">
              {listMessages.map((message) => {
                const _class =
                  message.idFrom === clientUid
                    ? "message-right container"
                    : "message-left container";
                return (
                  <div
                    className={_class}
                    key={moment(message.timestamp).toISOString()}
                  >
                    <p>{message.content}</p>
                    <span className="timestamp">
                      {moment(message.timestamp).format("MM-DD-YYYY LTS")}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="input-chat">
              <InputText
                value={newMessage}
                onChange={onChangeMessage}
                onKeyDown={onKeyInputMessageDown}
              />
              <Button onClick={onSendMessage} icon="pi pi-comment" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
