import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLSection } from "models/sections";
import { GQLCategory } from "models/category";

import { CATEGORIES_WITH_SECTIONS_QUERY } from "graphql/queries/categories/categoriesWithSections";
import { Auth } from "utils/auth";
import { Categories } from "components/Categories";

const CategoriesContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={CATEGORIES_WITH_SECTIONS_QUERY} clientUid={clientUid}>
      {({
        data: { categories, sections },
        refetch,
      }: QueryResponse<GQLSection> & QueryResponse<GQLCategory>) => {
        return (
          <Categories data={categories} sections={sections} refetch={refetch} />
        );
      }}
    </Query>
  );
};

export default CategoriesContainer;
