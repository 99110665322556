import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";

import {
  CHECK_IN_CONFIG_CREATE_MUTATION,
  CHECK_IN_CONFIG_UPDATE_MUTATION,
  CHECK_IN_CONFIG_DELETE_MUTATION,
} from "graphql/mutations/checkInConfig";
import { Auth } from "utils/auth";
import { GQLCheckInConfig, CHECK_IN_FIELD_TYPES } from "models/checkIn";
import { Dropdown } from "primereact/dropdown";

interface CheckInConfigProps {
  data: GQLCheckInConfig[];
  refetch: any;
}

export const CheckInConfig: React.FC<CheckInConfigProps> = ({
  data,
  refetch,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [blockedSignature, setBlockedSignature] = useState(
    !!data.find((item) => item.type === "signature")
  );
  const [order, setOrder] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.checkInConfig && response.checkInConfig.id) {
      setError(false);
      if (editId) {
        setMessage("The check in field has been updated!");
      } else {
        setMessage("The check in field has been created!");
      }

      refetch();
      setEditId(null);
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the check in field!");
      } else {
        setMessage("There was an error creating the check in field!");
      }
    }
    setName("");
    setLabel("");
    setType("");
    setDescription("");
    setIsRequired(false);
    setOrder(0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.checkInConfig && response.checkInConfig.id) {
      setError(false);
      setMessage("The check in field has been deleted!");
      if (type === "signature") {
        setBlockedSignature(true);
      }
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the check in field!");
    }
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={CHECK_IN_CONFIG_DELETE_MUTATION}
        variables={{
          checkInConfigId: rowData.id,
        }}
        onCompleted={({ deleteCheckInConfig }: any) => {
          deleted(deleteCheckInConfig);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setName(rowData.name);
          setLabel(rowData.label);
          setDescription(rowData.description);
          setIsRequired(rowData.required);
          setType(rowData.type);
          setOrder(rowData.order);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const booleanColumn = (rowData: any, column: any) => {
    return rowData[column.field] ? (
      <i className="pi pi-check"></i>
    ) : (
      <i className="pi pi-times"></i>
    );
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={
          editId
            ? CHECK_IN_CONFIG_UPDATE_MUTATION
            : CHECK_IN_CONFIG_CREATE_MUTATION
        }
        variables={{
          checkInConfigId: editId,
          clientId,
          clientUid,
          name,
          label,
          description,
          type,
          isRequired,
          order,
        }}
        onCompleted={({ createCheckInConfig, updateCheckInConfig }: any) => {
          upserted(createCheckInConfig || updateCheckInConfig);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Name:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className="p-col-12">Label (title):</div>
          <div className="p-col-12">
            <InputText
              placeholder="Label"
              value={label}
              onChange={(e: any) => setLabel(e.target.value)}
            />
          </div>
          <div className="p-col-12">Description:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Description or Why you ask it"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
          <div className="p-col-12">Type:</div>
          <div className="p-col-12">
            <Dropdown
              value={type}
              options={CHECK_IN_FIELD_TYPES.filter(
                (item) => !blockedSignature || item.value !== "signature"
              )}
              onChange={(e) => {
                setType(e.value);
              }}
              placeholder="Select a type"
            />
          </div>
          <div className="p-col-12">Is Required:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={isRequired}
              onChange={(event: any) => setIsRequired(event.value)}
            />
          </div>
          <div className="p-col-12">Order:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <h1>Check in fields</h1>
        <DataTable
          value={data}
          header="Fields"
          style={{ textAlign: "center" }}
          paginator={true}
          rows={10}
        >
          <Column field="name" header="Name" />
          <Column field="label" header="Label" />
          <Column field="type" header="Type" />
          <Column field="description" header="Description" />
          <Column field="required" header="Is Required" body={booleanColumn} />
          <Column field="order" header="Order" sortable={true} />
          <Column field="id" header="Edit" body={editColumn} />
          <Column field="id" header="Delete" body={deleteColumn} />
        </DataTable>
      </div>
    </>
  );
};
