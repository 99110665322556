import { Component } from "react";
import { withRouter } from "react-router-dom";

interface ScrollToTopProps {
  location: string;
}

class ScrollToTop extends Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter<any, any>(ScrollToTop);
