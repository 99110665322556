import gql from "graphql-tag";

export const SETTINGS_CREATE_MUTATION = gql`
  mutation CreateSettings(
    $clientId: ID!
    $clientUid: String!
    $checkIn: Boolean
    $services: Boolean
    $roomService: Boolean
  ) {
    createSetting(
      input: {
        data: {
          checkIn: $checkIn
          services: $services
          roomService: $roomService
          client: $clientId
          clientUid: $clientUid
        }
      }
    ) {
      setting {
        id
      }
    }
  }
`;

export const SETTINGS_UPDATE_MUTATION = gql`
  mutation UpdateSettings(
    $settingsId: ID!
    $checkIn: Boolean
    $services: Boolean
    $roomService: Boolean
  ) {
    updateSetting(
      input: {
        where: { id: $settingsId }
        data: {
          checkIn: $checkIn
          services: $services
          roomService: $roomService
        }
      }
    ) {
      setting {
        id
      }
    }
  }
`;
