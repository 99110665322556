import React, { Component } from "react";
import { Route } from "react-router-dom";
import classNames from "classnames";

import { Growl } from "primereact/growl";

import { MAIN_MENU_OPTIONS } from "config/menu";

import { PageDashboard } from "pages/Dashboard";
import { PageOrders } from "pages/Orders";
import { PageNotifications } from "pages/Notifications";
import { PageRooms } from "pages/Rooms";
import { PageViewRooms } from "pages/ViewRooms";
import { PageChat } from "pages/Chat";
import { PageHelp } from "pages/Help";
import { PageUsers } from "pages/Users";
import { PageLogin } from "pages/Login";
import { PageBranding } from "pages/Branding";
import { PageMyAccount } from "pages/MyAccount";
import { PageSettings } from "pages/Settings";
import { PageCheckInConfig } from "pages/CheckInConfig";
import { PageSections } from "pages/Sections";
import { PageServices } from "pages/Services";
import { PageCategories } from "pages/Categories";
import { PageSectionsInfo } from "pages/SectionsInfo";
import { PageGuests } from "pages/Guests";

import { PrivateRoute } from "components/PrivateRoute";

import { AppTopBar } from "./AppTopBar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { Auth } from "utils/auth";
import { initFCM, fbMessaging } from "config/firebase";
import Api from "api";

import "./layout/layout.scss";
import "./App.scss";

interface AppState {
  layoutMode: string;
  staticMenuInactive: boolean;
  mobileMenuActive: boolean;
  isAuthenticated: boolean;
  token: string;
}

class App extends Component<any, AppState> {
  private menuClick: boolean;
  private growl;

  constructor(props: any) {
    super(props);

    this.menuClick = false;

    this.state = {
      layoutMode: "static",
      staticMenuInactive: false,
      mobileMenuActive: false,
      isAuthenticated: Auth.isAuthenticated(),
      token: "",
    };
  }

  async componentDidMount() {
    if (this.state.isAuthenticated && !this.state.token) {
      try {
        const token = await initFCM();
        console.log(token);
        Api.registerToken(token);
        this.setState({
          ...this.state,
          token,
        });
      } catch (err) {
        console.log(err);
      }
    }

    fbMessaging.onMessage((payload) => {
      this.growl.show({
        severity: "success",
        summary: payload.notification.title,
        detail: payload.notification.body,
        onClick: () =>
          this.props.history.push(`/${payload.notification.section}`),
      });
      Api.markAsRead(payload.notification.id);
    });
  }

  onWrapperClick = () => {
    if (!this.menuClick) {
      this.setState({
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  };

  onToggleMenu = (event: any) => {
    this.menuClick = true;

    if (this.isDesktop()) {
      this.setState({
        staticMenuInactive: !this.state.staticMenuInactive,
      });
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  };

  onSidebarClick = () => {
    this.menuClick = true;
  };

  onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      this.setState({
        mobileMenuActive: false,
      });
    }
  };

  addClass(element: any, className: string) {
    if (element.classList) {
      element.classList.add(className);
    } else {
      element.className += " " + className;
    }
  }

  removeClass(element: any, className: string) {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive) {
      this.addClass(document.body, "body-overflow-hidden");
    } else {
      this.removeClass(document.body, "body-overflow-hidden");
    }
  }

  render() {
    const logo = `${process.env.PUBLIC_URL}/images/logo.svg`;
    const { isAuthenticated } = this.state;

    const wrapperClass = classNames("layout-wrapper", "layout-static", {
      "layout-static-sidebar-inactive": this.state.staticMenuInactive,
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
      "layout-full-width": !isAuthenticated,
    });

    const sidebarClassName = classNames(
      "layout-sidebar",
      "layout-sidebar-dark"
    );

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        {isAuthenticated ? (
          <>
            <AppTopBar onToggleMenu={this.onToggleMenu} />
            <Growl ref={(el) => (this.growl = el)} />
            <div className={sidebarClassName} onClick={this.onSidebarClick}>
              <div className="layout-logo">
                <img alt="Logo" src={logo} />
              </div>
              <AppMenu
                model={MAIN_MENU_OPTIONS}
                onMenuItemClick={this.onMenuItemClick}
              />
            </div>
          </>
        ) : null}

        <div className="layout-main">
          <Route path="/login" component={PageLogin} />
          <PrivateRoute path="/" exact component={PageDashboard} />
          <PrivateRoute
            path="/notifications"
            exact
            component={PageNotifications}
          />
          <PrivateRoute path="/setup/settings" exact component={PageSettings} />
          <PrivateRoute path="/setup/branding" exact component={PageBranding} />
          <PrivateRoute
            path="/setup/checkin"
            exact
            component={PageCheckInConfig}
          />
          <PrivateRoute path="/setup/sections" exact component={PageSections} />
          <PrivateRoute
            path="/setup/categories"
            exact
            component={PageCategories}
          />
          <PrivateRoute
            path="/setup/sectionsinfo"
            exact
            component={PageSectionsInfo}
          />
          <PrivateRoute path="/setup/services" exact component={PageServices} />
          <PrivateRoute path="/rooms" exact component={PageViewRooms} />
          <PrivateRoute path="/orders" exact component={PageOrders} />
          <PrivateRoute path="/setup/rooms" exact component={PageRooms} />
          <PrivateRoute path="/guests" exact component={PageGuests} />
          <PrivateRoute path="/chat" exact component={PageChat} />
          <PrivateRoute path="/help" exact component={PageHelp} />
          <PrivateRoute path="/my-account" exact component={PageMyAccount} />
          <PrivateRoute path="/setup/users" exact component={PageUsers} />
        </div>

        <AppFooter />

        <div className="layout-mask"></div>
      </div>
    );
  }
}

export default App;
