import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";

interface QueryProps {
  children: any;
  query: DocumentNode;
  id?: string;
  clientId?: string;
  userId?: string;
  clientUid?: string;
}
const Query = ({
  children,
  query,
  id,
  userId,
  clientId,
  clientUid,
}: QueryProps) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { id, clientId, clientUid, userId },
    fetchPolicy: "no-cache",
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data, refetch });
};

export default Query;
