import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLUser } from "models/user";
import { GQLRole } from "models/generic";

import USERS_QUERY from "graphql/queries/users/users";
import { Auth } from "utils/auth";
import { Users } from "components/Users";

const UsersContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={USERS_QUERY} clientUid={clientUid}>
      {({
        data: { users, roles },
        refetch,
      }: QueryResponse<GQLUser> & QueryResponse<GQLRole>) => {
        return <Users data={users} roles={roles} refetch={refetch} />;
      }}
    </Query>
  );
};

export default UsersContainer;
