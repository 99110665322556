import React from "react";

import { Panel } from "primereact/panel";

import { GQLDashboard } from "models/dashboard";
import { GQLOrder } from "models/orders";

interface DashboardProps {
  data: GQLDashboard;
  refetch: any;
}

export const Dashboard: React.FC<DashboardProps> = ({ data }) => {
  const employees = [...data.employees, ...data.admins, ...data.maintainers];
  return (
    <>
      <div className="p-col-12 p-lg-12">Historical</div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary">
          <span className="title">Guests</span>
          <span className="detail">Number of guests</span>
          <span className="count visitors">
            {data.totalGuests.aggregate.count}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary">
          <span className="title">Orders</span>
          <span className="detail">Number of orders</span>
          <span className="count purchases">
            {data.totalOrders.aggregate.count}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary">
          <span className="title">Messages</span>
          <span className="detail">Sent messages</span>
          <span className="count revenue">-</span>
        </div>
      </div>

      <div className="p-col-12 p-lg-12">Daily numbers</div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{ backgroundColor: "#f9c851", color: "#897831" }}
          >
            <span>CG</span>
          </div>
          <div className="highlight-details ">
            <i className="pi pi-users" />
            <span>Guests Today</span>
            <span className="count">
              {data.totalCurrentGuests.aggregate.count}
            </span>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{ backgroundColor: "#007be5", color: "#00448f" }}
          >
            <span>OP</span>
          </div>
          <div className="highlight-details ">
            <i className="pi pi-clock" />
            <span>Pending Orders</span>
            <span className="count">
              {data.totalPendingOrders.aggregate.count}
            </span>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{ backgroundColor: "#20d077", color: "#038d4a" }}
          >
            <span>OC</span>
          </div>
          <div className="highlight-details ">
            <i className="pi pi-money-bill" />
            <span>Orders Completed</span>
            <span className="count">
              {data.totalCompletedOrders.aggregate.count}
            </span>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{ backgroundColor: "#ef6262", color: "#a83d3b" }}
          >
            <span>OC</span>
          </div>
          <div className="highlight-details ">
            <i className="pi pi-exclamation-circle" />
            <span>Canceled Orders</span>
            <span className="count">
              {data.totalCanceledOrders.aggregate.count}
            </span>
          </div>
        </div>
      </div>

      <div className="p-col-12 p-md-6 p-lg-4">
        <Panel header="Pending Orders" style={{ height: "100%" }}>
          <ul className="activity-list">
            {(data.pendingOrders || []).map(
              ({ id, created, status, guest }: GQLOrder) => (
                <li key={id}>
                  <span className="activity-info">
                    <span className="activity-room">
                      <i className="pi pi-arrow-circle-right" />
                      {guest.room.name}:{" "}
                      <span className="activity-time">({created})</span>
                    </span>
                    {status}
                  </span>
                </li>
              )
            )}
          </ul>
        </Panel>
      </div>

      <div className="p-col-12 p-md-6 p-lg-4">
        <Panel header="Upcoming Events" style={{ height: "100%" }}>
          <ul className="activity-list">
            <li>-</li>
          </ul>
        </Panel>
      </div>

      <div className="p-col-12 p-lg-4 contacts">
        <Panel header="Employees">
          <ul>
            {(employees || []).map((employee: any) => (
              <li key={employee.id}>
                <button className="p-link">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/layout/images/avatar_1.png`}
                    width="35"
                    alt="avatar1"
                  />
                  <span className="name">{employee.name}</span>
                  <span className="email">{employee.role.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </Panel>
      </div>
    </>
  );
};
