import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

import {
  CATEGORY_CREATE_MUTATION,
  CATEGORY_UPDATE_MUTATION,
  CATEGORY_DELETE_MUTATION,
} from "graphql/mutations/categories";
import { Auth } from "utils/auth";
import { GQLSection } from "models/sections";
import { GQLCategory } from "models/category";

interface CategoriesProps {
  data: GQLCategory[];
  sections: GQLSection[];
  refetch: any;
}

export const Categories: React.FC<CategoriesProps> = ({
  data,
  sections,
  refetch,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [sectionId, setSectionId] = useState(null);
  const [order, setOrder] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [dataTableSelection, setDataTableSelection] = useState(null);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.category && response.category.id) {
      setError(false);
      if (editId) {
        setMessage("The category has been updated!");
      } else {
        setMessage("The category has been created!");
      }
      setEditId(null);
      refetch();
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the category!");
      } else {
        setMessage("There was an error creating the category!");
      }
    }
    setName("");
    setSectionId(null);
    setOrder(0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.category && response.category.id) {
      setError(false);
      setMessage("The category has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the category!");
    }
    setEditId(null);
    setName("");
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={CATEGORY_DELETE_MUTATION}
        variables={{
          categoryId: rowData.id,
        }}
        onCompleted={({ deleteCategory }: any) => {
          deleted(deleteCategory);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setName(rowData.name);
          setSectionId(rowData.section.id);
          setOrder(rowData.order);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? CATEGORY_UPDATE_MUTATION : CATEGORY_CREATE_MUTATION}
        variables={{
          name,
          clientId,
          clientUid,
          sectionId,
          categoryId: editId,
          order,
        }}
        onCompleted={({ createCategory, updateCategory }: any) => {
          upserted(createCategory || updateCategory);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };
  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Name:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className="p-col-12">Section:</div>
          <div className="p-col-12">
            <Dropdown
              value={sectionId}
              optionLabel="name"
              optionValue="id"
              options={sections}
              onChange={(e) => {
                setSectionId(e.value);
              }}
              placeholder="Select a Section"
            />
          </div>
          <div className="p-col-12">Order:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <h1>Categories</h1>
        <DataTable
          value={data}
          selectionMode="single"
          header="Sections"
          selection={dataTableSelection}
          onSelectionChange={(event: any) => setDataTableSelection(event.value)}
        >
          <Column field="id" header="Id" sortable={false} />
          <Column field="name" header="Name" sortable={true} />
          <Column
            field="section"
            header="Section"
            sortable={true}
            body={(rowData: any) => rowData.section.name}
          />
          <Column field="order" header="Order" sortable={true} />
          <Column field="id" header="Edit" sortable={false} body={editColumn} />
          <Column
            field="id"
            header="Delete"
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
