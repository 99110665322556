import gql from "graphql-tag";

export const BRANDING_CREATE_MUTATION = gql`
  mutation CreateBranding(
    $clientId: ID!
    $clientUid: String!
    $title: String
    $customDomain: String
    $primaryColor: String
    $secondaryColor: String
    $primaryText: String
    $secondaryText: String
  ) {
    createBranding(
      input: {
        data: {
          title: $title
          primaryText: $primaryText
          primaryColor: $primaryColor
          secondaryText: $secondaryText
          secondaryColor: $secondaryColor
          client: $clientId
          clientUid: $clientUid
          customDomain: $customDomain
        }
      }
    ) {
      branding {
        id
      }
    }
  }
`;

export const BRANDING_UPDATE_MUTATION = gql`
  mutation UpdateBranding(
    $brandingId: ID!
    $title: String
    $customDomain: String
    $primaryColor: String
    $secondaryColor: String
    $primaryText: String
    $secondaryText: String
  ) {
    updateBranding(
      input: {
        where: { id: $brandingId }
        data: {
          title: $title
          primaryText: $primaryText
          primaryColor: $primaryColor
          secondaryText: $secondaryText
          secondaryColor: $secondaryColor
          customDomain: $customDomain
        }
      }
    ) {
      branding {
        id
      }
    }
  }
`;
