import React from "react";
import moment from "moment";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { GQLOrder } from "models/orders";

import DropdownStatus from "components/DropdownStatus";
import { DATE_TIME_FORMAT } from "config/constants";
import { GQLService } from "models/services";
import { normalizeArray } from "utils/arrays";

interface OrdersProps {
  data: GQLOrder[];
  services: GQLService[];
  refetch: any;
}

const Orders: React.FC<OrdersProps> = ({ data, services, refetch }) => {
  const normalizedServices = normalizeArray(services);

  const dropdownStatus = (rowData: any, column: any) => {
    return (
      <DropdownStatus
        orderId={rowData.id}
        guestId={rowData.guest.id}
        status={rowData.status}
        refetch={refetch}
      />
    );
  };

  return (
    <div className="card card-w-title">
      <DataTable value={data} header="Orders" style={{ textAlign: "center" }}>
        <Column
          field="guest"
          header="Guest"
          body={(rowData) => {
            const user = rowData.guest.user;
            return user.name || user.email;
          }}
        />
        <Column
          field="room"
          header="Room"
          body={(rowData) => {
            const room = rowData.room || rowData.guest.room;
            return room.name;
          }}
        />
        <Column
          field="idType"
          header="Service"
          body={(rowData) => {
            const service = normalizedServices[rowData.idType];
            return service.label;
          }}
        />
        <Column
          field="status"
          header="Status"
          body={dropdownStatus}
          sortable={true}
        />
        <Column
          field="lastUpdate"
          header="Last update"
          body={(rowData: any) =>
            moment(rowData.lastUpdate).format(DATE_TIME_FORMAT)
          }
          sortable={true}
        />
        <Column
          field="created"
          header="Created"
          body={(rowData: any) =>
            moment(rowData.created).format(DATE_TIME_FORMAT)
          }
          sortable={true}
        />
      </DataTable>
    </div>
  );
};

export default Orders;
