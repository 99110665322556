import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import {
  ROOM_CREATE_MUTATION,
  ROOM_UPDATE_MUTATION,
  ROOM_DELETE_MUTATION,
} from "graphql/mutations/rooms";
import { Auth } from "utils/auth";
import { GQLRoom } from "../../models/room";
import {
  doNotDisturb as componentDoNotDisturb,
  laundry as componentLaundry,
} from "utils/components";

interface RoomsProps {
  data: GQLRoom[];
  refetch: any;
}

const Rooms: React.FC<RoomsProps> = ({ data, refetch }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [floor, setFloor] = useState("");
  const [notDisturb, setNotDisturb] = useState(false);
  const [laundry, setLaundry] = useState(false);
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.room && response.room.id) {
      if (editId) {
        setMessage("The room has been updated!");
      } else {
        setMessage("The room has been created!");
      }
      setEditId(null);
      setError(false);
      refetch();
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the room!");
      } else {
        setMessage("There was an error creating the room!");
      }
    }
    setName("");
    setFloor("");
    setDescription("");
    setNotDisturb(false);
    setLaundry(false);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.room && response.room.id) {
      setError(false);
      setMessage("The room has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the room!");
    }
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={ROOM_DELETE_MUTATION}
        variables={{
          roomId: rowData.id,
        }}
        onCompleted={({ deleteRoom }: any) => {
          deleted(deleteRoom);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setName(rowData.name);
          setFloor(rowData.floor);
          setDescription(rowData.description);
          setNotDisturb(rowData.notDisturb);
          setLaundry(rowData.laundry);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const booleanDoNotDisturb = (rowData: any, column: any) => {
    return componentDoNotDisturb(rowData[column.field]);
  };

  const booleanLaundry = (rowData: any, column: any) => {
    return componentLaundry(rowData[column.field]);
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? ROOM_UPDATE_MUTATION : ROOM_CREATE_MUTATION}
        variables={{
          name,
          clientId,
          clientUid,
          roomId: editId,
          description,
          floor,
          notDisturb,
          laundry,
        }}
        onCompleted={({ createRoom, updateRoom }: any) => {
          upserted(createRoom || updateRoom);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Name:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className="p-col-12">Floor:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Floor"
              value={floor}
              onChange={(e: any) => setFloor(e.target.value)}
            />
          </div>
          <div className="p-col-12">Description:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Description"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
          <div className="p-col-12">Do not disturb:</div>
          <div className="p-col-12">
            {componentLaundry(notDisturb, (e: any) => {
              setNotDisturb(e.value);
            })}
          </div>
          <div className="p-col-12">Laundry:</div>
          <div className="p-col-12">
            {componentLaundry(laundry, (e: any) => {
              setLaundry(e.value);
            })}
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <DataTable value={data} header="Rooms" style={{ textAlign: "center" }}>
          <Column field="name" header="Name" sortable={true} />
          <Column field="floor" header="Floor" sortable={true} />
          <Column field="description" header="Description" sortable={true} />
          <Column
            field="notDisturb"
            header="Do not disturb"
            body={booleanDoNotDisturb}
            sortable={true}
          />
          <Column
            field="laundry"
            header="Laundry"
            body={booleanLaundry}
            sortable={true}
          />
          <Column field="id" header="Edit" sortable={false} body={editColumn} />
          <Column
            field="id"
            header="Delete"
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};

export default Rooms;
