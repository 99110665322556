import gql from "graphql-tag";

export const ORDERS_QUERY = gql`
  query Orders($clientUid: String!) {
    orders(where: { clientUid: $clientUid }, sort: "lastUpdate:desc") {
      id
      status
      guest {
        id
        user {
          name
          email
        }
        room {
          name
        }
      }
      room {
        name
      }
      lastUpdate
      created
      type
      idType
    }

    services(where: { clientUid: $clientUid }) {
      id
      label
      description
      price
    }
  }
`;
