import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLCheckInConfig } from "models/checkIn";

import CHECK_IN_CONFIG_QUERY from "graphql/queries/checkIn/checkInConfig";
import { Auth } from "utils/auth";
import { CheckInConfig } from "components/CheckInConfig";

const CheckInContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={CHECK_IN_CONFIG_QUERY} clientUid={clientUid}>
      {({
        data: { checkInConfigs },
        refetch,
      }: QueryResponse<GQLCheckInConfig>) => {
        return <CheckInConfig data={checkInConfigs} refetch={refetch} />;
      }}
    </Query>
  );
};

export default CheckInContainer;
