import React, { useState } from "react";
import Axios from "axios";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";

import {
  DATA_SECTIONS_CREATE_MUTATION,
  DATA_SECTIONS_UPDATE_MUTATION,
  DATA_SECTIONS_DELETE_MUTATION,
} from "graphql/mutations/dataSections";
import { Auth } from "utils/auth";
import { GQLSection } from "models/sections";
import { GQLCategory } from "models/category";
import { GQLDataSection } from "models/dataSections";

interface DataSectionsProps {
  data: GQLDataSection[];
  categories: GQLCategory[];
  sections: GQLSection[];
  refetch: any;
}

export const DataSections: React.FC<DataSectionsProps> = ({
  data,
  sections,
  categories,
  refetch,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subDescription, setSubDescription] = useState("");
  const [order, setOrder] = useState(0);
  const [sectionId, setSectionId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [pendingFiles, setPendingFiles] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [filter, setFilter] = useState({
    section: null,
    category: null,
  });
  const [filterSectionName, setFilterSectionName] = useState(null);
  const [filterCategoryName, setFilterCategoryName] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.dataSection && response.dataSection.id) {
      setError(false);
      if (editId) {
        setMessage("The data section has been updated!");
      } else {
        setMessage("The data section has been created!");
      }
      if (pendingFiles) {
        const formData = new FormData();
        formData.set("ref", "data-sections");
        formData.set("field", "image");
        const file = pendingFiles[0];
        formData.append("files", file, file.name);
        formData.set("refId", response.dataSection.id);
        const token = Auth.getToken();
        Axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token ? `Bearer ${token}` : "",
          },
        })
          .then(() => {
            refetch();
          })
          .catch((response: any) => {
            console.log(response);
          })
          .finally(() => {
            setPendingFiles(null);
          });
      } else {
        refetch();
      }
      setEditId(null);
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the data section!");
      } else {
        setMessage("There was an error creating the data section!");
      }
    }
    setTitle("");
    setDescription("");
    setSubDescription("");
    setSectionId(null);
    setCurrentPhoto(null);
    setOrder(0);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.dataSection && response.dataSection.id) {
      setError(false);
      setMessage("The data section has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the data section!");
    }
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={DATA_SECTIONS_DELETE_MUTATION}
        variables={{
          dataSectionId: rowData.id,
        }}
        onCompleted={({ deleteDataSection }: any) => {
          deleted(deleteDataSection);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setTitle(rowData.title);
          setDescription(rowData.description);
          setSubDescription(rowData.subDescription);
          setSectionId(rowData.section ? rowData.section.id : null);
          setCategoryId(rowData.category ? rowData.category.id : null);
          setOrder(rowData.order);
          setCurrentPhoto(rowData.image ? rowData.image.url : null);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={
          editId ? DATA_SECTIONS_UPDATE_MUTATION : DATA_SECTIONS_CREATE_MUTATION
        }
        variables={{
          title,
          description,
          subDescription,
          clientId,
          clientUid,
          order,
          sectionId,
          categoryId,
          dataSectionId: editId,
        }}
        onCompleted={({ createDataSection, updateDataSection }: any) => {
          upserted(createDataSection || updateDataSection);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const dataViewHeader = (
    <div className="p-grid">
      <div className="p-col-3 filter-container">
        <div style={{ position: "relative" }}>
          <Dropdown
            value={filterSectionName}
            optionLabel="name"
            optionValue="name"
            showClear={true}
            options={sections}
            onChange={(e) => {
              setFilterSectionName(e.value);
              setFilter({
                ...filter,
                section: e.value,
              });
            }}
            placeholder="Filter by Section"
          />
        </div>
      </div>
      <div className="p-col-3 filter-container">
        <div style={{ position: "relative" }}>
          <Dropdown
            value={filterCategoryName}
            optionValue="name"
            optionLabel="name"
            showClear={true}
            options={categories.filter(
              (category: any) =>
                !filterSectionName ||
                category.section.name === filterSectionName
            )}
            onChange={(e) => {
              setFilterCategoryName(e.value);
              setFilter({
                ...filter,
                category: e.value,
              });
            }}
            placeholder="Filter by Category"
          />
        </div>
      </div>
    </div>
  );

  const imageColumn = (rowData: any, column: any) => {
    return rowData[column.field] && rowData[column.field].url ? (
      <img
        src={`${process.env.REACT_APP_BACKEND_URL}${rowData[column.field].url}`}
        width="100%"
        height="auto"
        alt=""
      />
    ) : null;
  };

  const setFormData = (event: any) => {
    setPendingFiles(event.files);
  };

  const filteredData =
    data.filter(
      (rowData: GQLDataSection) =>
        (!filter.section ||
          (filter.section &&
            rowData.section &&
            rowData.section.name === filter.section)) &&
        (!filter.category ||
          (filter.category &&
            rowData.category &&
            rowData.category.name === filter.category))
    ) || null;

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Title:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Title"
              value={title}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </div>
          <div className="p-col-12">Description:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Description"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
          <div className="p-col-12">Foot Text:</div>
          <div className="p-col-12">
            <InputText
              placeholder="More information..."
              value={subDescription}
              onChange={(e: any) => setSubDescription(e.target.value)}
            />
          </div>
          <div className="p-col-12">Section:</div>
          <div className="p-col-12">
            <Dropdown
              value={sectionId}
              optionLabel="name"
              optionValue="id"
              showClear={true}
              disabled={!!categoryId}
              options={sections}
              onChange={(e) => {
                setSectionId(e.value);
              }}
              placeholder="Select a Section"
            />
          </div>
          <div className="p-col-12">Category:</div>
          <div className="p-col-12">
            <Dropdown
              value={categoryId}
              optionLabel="name"
              optionValue="id"
              showClear={true}
              disabled={!!sectionId}
              options={categories}
              onChange={(e) => {
                setCategoryId(e.value);
              }}
              placeholder="Select a Category"
            />
          </div>
          <div className="p-col-12">Order:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
          <div className="p-col-12">Image:</div>
          {currentPhoto ? (
            <div className="p-col-12">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${currentPhoto}`}
                width="100%"
                height="auto"
                alt=""
              />
            </div>
          ) : null}
          <div className="p-col-12">
            <FileUpload
              name="logo"
              url="./upload"
              accept="image/*"
              customUpload={true}
              mode="basic"
              auto={true}
              uploadHandler={setFormData}
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <h1>Data Sections &amp; Categories</h1>
        <DataTable
          value={filteredData.length ? filteredData : null}
          selectionMode="single"
          header={dataViewHeader}
          paginator={true}
          rows={10}
          style={{ textAlign: "center" }}
        >
          <Column field="title" header="Title" />
          <Column field="description" header="Description" />
          <Column field="subDescription" header="Foot" />
          <Column
            field="section"
            header="Section"
            sortable={true}
            body={(rowData: any) =>
              rowData.section ? rowData.section.name : ""
            }
          />
          <Column
            field="category"
            header="Cat"
            sortable={true}
            body={(rowData: any) =>
              rowData.category ? rowData.category.name : ""
            }
          />
          <Column field="order" header="Order" sortable={true} />
          <Column
            field="image"
            header="Image"
            sortable={false}
            body={imageColumn}
          />
          <Column field="id" header="Edit" sortable={false} body={editColumn} />
          <Column
            field="id"
            header="Delete"
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
