import React from "react";

import GuestsContainer from "../../containers/Guests";

export const PageGuests = () => {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-w-title">
          <h1>Guests</h1>
          <GuestsContainer />
        </div>
      </div>
    </div>
  );
};
