import React from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { GQLRoom } from "../../models/room";
import { doNotDisturb, laundry } from "utils/components";

interface ViewRoomsProps {
  data: GQLRoom[];
  refetch: any;
}

const ViewRooms: React.FC<ViewRoomsProps> = ({ data }) => {
  const booleanDoNotDisturb = (rowData: any, column: any) => {
    return doNotDisturb(rowData[column.field]);
  };

  const booleanLaundry = (rowData: any, column: any) => {
    return laundry(rowData[column.field]);
  };

  return (
    <div className="card card-w-title">
      <DataTable value={data} header="Rooms" style={{ textAlign: "center" }}>
        <Column field="name" header="Name" sortable={true} />
        <Column field="floor" header="Floor" sortable={true} />
        <Column
          field="notDisturb"
          header="Do not disturb"
          body={booleanDoNotDisturb}
          sortable={true}
        />
        <Column
          field="laundry"
          header="Laundry"
          body={booleanLaundry}
          sortable={true}
        />
      </DataTable>
    </div>
  );
};

export default ViewRooms;
