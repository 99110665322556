import gql from "graphql-tag";

export const CATEGORIES_WITH_SECTIONS_QUERY = gql`
  query CategoriesAndSections($clientUid: String!) {
    categories(where: { clientUid: $clientUid }) {
      id
      name
      section {
        id
        name
      }
      order
    }

    sections(where: { clientUid: $clientUid }) {
      id
      name
    }
  }
`;
