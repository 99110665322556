import gql from "graphql-tag";

export const ROOM_CREATE_MUTATION = gql`
  mutation CreateRoom(
    $clientId: ID!
    $clientUid: String!
    $name: String!
    $floor: String
    $description: String
    $notDisturb: Boolean
    $laundry: Boolean
  ) {
    createRoom(
      input: {
        data: {
          name: $name
          client: $clientId
          clientUid: $clientUid
          description: $description
          floor: $floor
          notDisturb: $notDisturb
          laundry: $laundry
        }
      }
    ) {
      room {
        id
      }
    }
  }
`;

export const ROOM_UPDATE_MUTATION = gql`
  mutation UpdateRoom(
    $roomId: ID!
    $name: String
    $description: String
    $floor: String
    $laundry: Boolean
    $notDisturb: Boolean
  ) {
    updateRoom(
      input: {
        where: { id: $roomId }
        data: {
          name: $name
          description: $description
          floor: $floor
          notDisturb: $notDisturb
          laundry: $laundry
        }
      }
    ) {
      room {
        id
      }
    }
  }
`;

export const ROOM_DELETE_MUTATION = gql`
  mutation DeleteRoom($roomId: ID!) {
    deleteRoom(input: { where: { id: $roomId } }) {
      room {
        id
      }
    }
  }
`;
