import gql from "graphql-tag";

const MY_ACCOUNT_QUERY = gql`
  query MyAccount($userId: ID!) {
    users(where: { id: $userId }) {
      id
      name
      username
      email
      role {
        id
        name
      }
      blocked
    }
  }
`;

export default MY_ACCOUNT_QUERY;
