import Axios, { AxiosResponse } from "axios";
import { Auth } from "utils/auth";

class Api {
  static getAuthHeaders() {
    const token = Auth.getToken();
    return {
      authorization: token ? `Bearer ${token}` : "",
    };
  }

  static findUserByEmail(email: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/users?email_eq=${email}&_limit=1`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static registerToken(token: string) {
    const clientId = Auth.getClientId();
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/clients/${clientId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          fcmToken: token,
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static sendNotification(guestId: string, section: string) {
    const clientId = Auth.getClientId();
    const clientUid = Auth.getClientUid();
    return new Promise((resolve, reject) => {
      Axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/notifications`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          client: clientId,
          clientUid,
          guest: guestId,
          section,
          origin: "client",
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  static getNewNotifications() {
    const clientUid = Auth.getClientUid();
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/notifications/?clientUid_eq=${clientUid}&status_eq=unread&origin=guest`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static markAsRead(notificationId: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/notifications/${notificationId}`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          status: "read",
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static findBrandImage(clientUid: string) {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/brandings?clientUid_eq=${clientUid}&_limit=1`,
        headers: {
          ...this.getAuthHeaders(),
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve((data && data[0] && data[0].logo.url) || "");
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  static generatePassword() {
    const passLength = 6;
    let charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < passLength; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  static createNewUser(email: string, name: string) {
    return new Promise((resolve, reject) => {
      const password = this.generatePassword();
      Axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users`,
        headers: {
          ...this.getAuthHeaders(),
        },
        data: {
          name,
          email,
          username: email,
          password,
          confirmed: true,
          blocked: false,
        },
      })
        .then(({ data }: AxiosResponse) => {
          resolve(data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}

export default Api;
