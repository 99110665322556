import React from "react";

import "./Help.scss";

export const PageHelp = () => (
  <div className="p-grid">
    <div className="p-col-12">
      <div className="card docs">
        <h1>Klivr Club Admin</h1>
        <p>Current version: 1.0.0 (Beta)</p>
        <h1>Getting Started (WIP)</h1>
        <p>
          In order to start using the platform you need to setup your account.
          To do that you should start by completing all the sections inside the
          "Setup" menu option
        </p>
        <p>
          In the following sections you will see what is every functionality
          meant for.
        </p>
        <pre>
          {`
+ Dashboard
+ Orders
+ Activities
+ Rooms
+ Guests
+ Chat
+ Setup
...
          `}
        </pre>
        <h3>Branding</h3>
        <p>Here you can configure how your Guests will see the application</p>
        <h3>Settings</h3>
        <p>
          We have some sections that are predefined, but you can always
          show/hide them changing the setting here.
        </p>
        <ul>
          <li>Check in: Pre check-in (You need to configure your form)</li>
          <li>Services: ...</li>
          <li>Room Service: ...</li>
        </ul>
      </div>
    </div>
  </div>
);
