import React from "react";

import CategoriesContainer from "containers/Categories";

export const PageCategories: React.FC = () => {
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>Categories Setup</h1>
            <CategoriesContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
