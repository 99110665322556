import React from "react";
import { InputSwitch } from "primereact/inputswitch";

export const doNotDisturb = (value: boolean, onChange: any = undefined) => {
  return (
    <InputSwitch
      checked={value}
      style={!onChange ? { pointerEvents: "none" } : null}
      onChange={onChange || null}
    />
  );
};

export const laundry = (value: boolean, onChange: any = undefined) => {
  return (
    <InputSwitch
      checked={value}
      style={!onChange ? { pointerEvents: "none" } : null}
      onChange={onChange || null}
    />
  );
};
