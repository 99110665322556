import React, { useState } from "react";
import Axios from "axios";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";

import {
  SECTION_CREATE_MUTATION,
  SECTION_UPDATE_MUTATION,
  SECTION_DELETE_MUTATION,
} from "graphql/mutations/sections";
import { Auth } from "utils/auth";
import { GQLSection } from "models/sections";

interface SectionsProps {
  data: GQLSection[];
  refetch: any;
}

export const Sections: React.FC<SectionsProps> = ({ data, refetch }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [slider, setSlider] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [order, setOrder] = useState(0);
  const [pendingFiles, setPendingFiles] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [dataTableSelection, setDataTableSelection] = useState(null);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.section && response.section.id) {
      setError(false);
      if (editId) {
        setMessage("The section has been updated!");
      } else {
        setMessage("The section has been created!");
      }

      if (pendingFiles) {
        const formData = new FormData();
        formData.set("ref", "sections");
        formData.set("field", "image");
        const file = pendingFiles[0];
        formData.append("files", file, file.name);
        formData.set("refId", response.section.id);
        const token = Auth.getToken();
        Axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token ? `Bearer ${token}` : "",
          },
        })
          .then(() => {
            refetch();
          })
          .catch((response: any) => {
            console.log(response);
          })
          .finally(() => {
            setPendingFiles(null);
          });
      } else {
        refetch();
      }
      setEditId(null);
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the section!");
      } else {
        setMessage("There was an error creating the section!");
      }
    }
    setName("");
    setIsVisible(true);
    setSlider(false);
    setOrder(0);
    setCurrentPhoto(null);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.section && response.section.id) {
      setError(false);
      setMessage("The section has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the section!");
    }
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={SECTION_DELETE_MUTATION}
        variables={{
          sectionId: rowData.id,
        }}
        onCompleted={({ deleteSection }: any) => {
          deleted(deleteSection);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setName(rowData.name);
          setIsVisible(rowData.visible);
          setSlider(rowData.slider);
          setOrder(rowData.order);
          setShowAdd(true);
          setCurrentPhoto(rowData.image ? rowData.image.url : null);
        }}
      ></Button>
    );
  };

  const booleanColumn = (rowData: any, column: any) => {
    return rowData[column.field] ? (
      <i className="pi pi-check"></i>
    ) : (
      <i className="pi pi-times"></i>
    );
  };

  const imageColumn = (rowData: any, column: any) => {
    return rowData[column.field] && rowData[column.field].url ? (
      <img
        src={`${process.env.REACT_APP_BACKEND_URL}${rowData[column.field].url}`}
        width="100%"
        height="auto"
        alt=""
      />
    ) : null;
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={editId ? SECTION_UPDATE_MUTATION : SECTION_CREATE_MUTATION}
        variables={{
          name,
          clientId,
          clientUid,
          sectionId: editId,
          order,
          slider,
          isVisible,
        }}
        onCompleted={({ createSection, updateSection }: any) => {
          upserted(createSection || updateSection);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  const setFormData = (event: any) => {
    setPendingFiles(event.files);
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Name:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className="p-col-12">Visible:</div>
          <div className="p-col-12">
            <ToggleButton
              checked={isVisible}
              onChange={(event: any) => setIsVisible(event.value)}
            />
          </div>
          <div className="p-col-12">Slider:</div>
          <div className="p-col-12">
            <InputSwitch
              checked={slider}
              onChange={(event: any) => setSlider(event.value)}
            />
          </div>
          <div className="p-col-12">Order:</div>
          <div className="p-col-12">
            <InputNumber
              value={order}
              onChange={(e) => setOrder(e.value)}
              showButtons
              mode="decimal"
            />
          </div>
          <div className="p-col-12">Image:</div>
          {currentPhoto ? (
            <div className="p-col-12">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${currentPhoto}`}
                width="100%"
                height="auto"
                alt=""
              />
            </div>
          ) : null}
          <div className="p-col-12">
            <FileUpload
              name="logo"
              url="./upload"
              accept="image/*"
              customUpload={true}
              mode="basic"
              auto={true}
              uploadHandler={setFormData}
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <h1>Sections</h1>
        <DataTable
          value={data}
          selectionMode="single"
          header="Sections"
          style={{ textAlign: "center" }}
          selection={dataTableSelection}
          onSelectionChange={(event: any) => setDataTableSelection(event.value)}
          paginator={true}
          rows={10}
        >
          <Column field="name" header="Name" sortable={true} />
          <Column field="visible" header="Is Visible" body={booleanColumn} />
          <Column field="slider" header="Slider" body={booleanColumn} />
          <Column field="order" header="Order" sortable={true} />
          <Column
            field="image"
            header="Image"
            sortable={false}
            body={imageColumn}
          />
          <Column field="id" header="Edit" sortable={false} body={editColumn} />
          <Column
            field="id"
            header="Delete"
            sortable={false}
            body={deleteColumn}
          />
        </DataTable>
      </div>
    </>
  );
};
