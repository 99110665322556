import gql from "graphql-tag";

const CHAT_QUERY = gql`
  query Chats($clientUid: String!) {
    guests(where: { clientUid: $clientUid, active: true }) {
      id
      room {
        id
        name
      }
      user {
        id
        email
        name
      }
      chatRoom
    }
  }
`;

export default CHAT_QUERY;
