import gql from "graphql-tag";

export const DATA_SECTIONS_WITH_EXTRA_DATA_QUERY = gql`
  query DataSectionsWithExtraData($clientUid: String!) {
    categories(where: { clientUid: $clientUid }) {
      id
      name
      section {
        name
      }
    }

    sections(where: { clientUid: $clientUid }) {
      id
      name
    }

    dataSections(where: { clientUid: $clientUid }) {
      id
      title
      description
      subDescription
      image {
        url
      }
      order
      section {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`;
