import React from "react";

import ServicesContainer from "containers/Services";

export const PageServices: React.FC = () => {
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>Services</h1>
            <p>
              Here you can add the services that will be shown in the Services
              section
            </p>
            <ServicesContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
