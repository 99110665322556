import React from "react";

import Query from "components/Query";
import { Chat } from "components/Chat";
import CHAT_QUERY from "graphql/queries/guests/chat";
import { QueryResponse } from "models/query";
import { GQLGuest } from "models/guest";
import { Auth } from "utils/auth";

const ChatContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <Query query={CHAT_QUERY} clientUid={clientUid}>
      {({ data: { guests }, refetch }: QueryResponse<GQLGuest>) => {
        return <Chat data={guests} refetch={refetch} />;
      }}
    </Query>
  );
};

export default ChatContainer;
