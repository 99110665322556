import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";

import App from "./App";
import ScrollToTop from "./ScrollToTop";
import client from "./utils/apolloClient";

ReactDOM.render(
  <HashRouter>
    <ApolloProvider client={client}>
      <ScrollToTop>
        <App></App>
      </ScrollToTop>
    </ApolloProvider>
  </HashRouter>,
  document.getElementById("root")
);
