import gql from "graphql-tag";

export const CATEGORY_CREATE_MUTATION = gql`
  mutation CreateCategory(
    $clientId: ID!
    $clientUid: String!
    $name: String!
    $sectionId: ID
    $order: Int
  ) {
    createCategory(
      input: {
        data: {
          name: $name
          client: $clientId
          clientUid: $clientUid
          section: $sectionId
          order: $order
        }
      }
    ) {
      category {
        id
      }
    }
  }
`;

export const CATEGORY_UPDATE_MUTATION = gql`
  mutation UpdateCategory(
    $categoryId: ID!
    $name: String!
    $sectionId: ID
    $order: Int
  ) {
    updateCategory(
      input: {
        where: { id: $categoryId }
        data: { name: $name, section: $sectionId, order: $order }
      }
    ) {
      category {
        id
      }
    }
  }
`;

export const CATEGORY_DELETE_MUTATION = gql`
  mutation DeleteCategory($categoryId: ID!) {
    deleteCategory(input: { where: { id: $categoryId } }) {
      category {
        id
      }
    }
  }
`;
