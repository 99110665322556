export const AUTH_TOKEN = "klivr-hotel-app-token";
export const STORAGE_ROLE = "klivr-hotel-app-role";
export const STORAGE_CLIENT_ID = "klivr-hotel-app-client-id";
export const STORAGE_CLIENT_UID = "klivr-hotel-app-client-uid";
export const STORAGE_USER_ID = "klivr-hotel-app-user-id";

export const VALID_ROLES = ["Employee", "Maintainer", "Admin"];

export const DATE_FORMAT = "DD-MM-YYYY";
export const GQL_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";
