import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLSettings } from "models/settings";

import { SETTINGS_QUERY } from "graphql/queries/settings/settings";
import { Auth } from "utils/auth";
import { Settings } from "components/Settings";

const SettingsContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <div>
      <Query query={SETTINGS_QUERY} clientUid={clientUid}>
        {({ data: { settings }, refetch }: QueryResponse<GQLSettings>) => {
          return (
            <Settings
              data={(settings || [{} as GQLSettings])[0]}
              refetch={refetch}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default SettingsContainer;
