import gql from "graphql-tag";

export const SERVICES_QUERY = gql`
  query Services($clientUid: String!) {
    services(where: { clientUid: $clientUid }) {
      id
      label
      description
      price
      image {
        url
      }
      type
      order
    }
  }
`;
