import gql from "graphql-tag";

export const USER_CREATE_MUTATION = gql`
  mutation CreateUser(
    $clientId: ID!
    $clientUid: String!
    $name: String!
    $username: String!
    $email: String!
    $password: String!
    $role: ID!
  ) {
    createUser(
      input: {
        data: {
          client: $clientId
          clientUid: $clientUid
          name: $name
          email: $email
          password: $password
          username: $username
          role: $role
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const USER_UPDATE_MUTATION = gql`
  mutation UpdateUser(
    $userId: ID!
    $name: String!
    $username: String!
    $email: String!
    $role: ID!
  ) {
    updateUser(
      input: {
        where: { id: $userId }
        data: {
          name: $name
          email: $email
          username: $username
          role: $role
          confirmed: true
          blocked: false
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const USER_PASSWORD_UPDATE_MUTATION = gql`
  mutation UpdateUser(
    $userId: ID!
    $name: String!
    $username: String!
    $email: String!
    $password: String!
    $role: ID!
  ) {
    updateUser(
      input: {
        where: { id: $userId }
        data: {
          name: $name
          email: $email
          username: $username
          role: $role
          password: $password
          confirmed: true
          blocked: false
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const USER_DELETE_MUTATION = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(input: { where: { id: $userId } }) {
      user {
        id
      }
    }
  }
`;
