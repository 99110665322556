import React from "react";

import DashboardContainer from "containers/Dashboard";
export const PageDashboard: React.FC = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <DashboardContainer />
    </div>
  );
};
