import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Mutation } from "react-apollo";

import { LOGIN_MUTATION } from "graphql/mutations/login";
import { Auth } from "utils/auth";
import { GQLAuthResponse, GQLLogin } from "models/auth";
import { VALID_ROLES } from "config/constants";

import "./Login.scss";

interface PageLoginProps {}

export const PageLogin: React.FC<PageLoginProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorClient, setErrorClient] = useState(false);

  if (Auth.isAuthenticated()) {
    window.location.href = "/";
  }

  const loggedIn = (login?: GQLLogin) => {
    if (
      login &&
      login.jwt &&
      login.user &&
      login.user.role &&
      VALID_ROLES.includes(login.user.role.name)
    ) {
      setErrorClient(false);
      Auth.authenticate(login.jwt, login.user.role.name, login.user.id || "")
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
          Auth.clear();
          setErrorClient(true);
        });
    } else {
      Auth.clear();
      setError(true);
    }
  };

  const onKeyInputDown = (mutation: any) => (e: any) => {
    if (e.key === "Enter" && email.length && password.length) {
      mutation();
    }
  };

  return (
    <div className="p-grid btn-login-container">
      <Mutation
        mutation={LOGIN_MUTATION}
        variables={{ email: email.toLocaleLowerCase(), password }}
        onCompleted={({ login }: GQLAuthResponse) => loggedIn(login)}
      >
        {(mutation: any) => (
          <div className="wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo_black.svg`}
              alt="Klivr Club"
            />
            <span className="p-float-label">
              <InputText
                id="in"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={onKeyInputDown(mutation)}
              />
              <label htmlFor="in">Email</label>
            </span>
            <span className="p-float-label">
              <Password
                id="in"
                value={password}
                feedback={false}
                onKeyDown={onKeyInputDown(mutation)}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <label htmlFor="in">Password</label>
            </span>

            {error ? (
              <div className="error-msg">Error in your email or password</div>
            ) : null}
            {errorClient ? <div className="error-msg">Invalid User</div> : null}
            <div className="button-container">
              <Button
                label="Login"
                className="p-button-raised"
                onClick={() => mutation({ email, password })}
              />
            </div>
          </div>
        )}
      </Mutation>
    </div>
  );
};
