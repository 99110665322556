import React, { useState } from "react";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";

import {
  USER_CREATE_MUTATION,
  USER_UPDATE_MUTATION,
  USER_PASSWORD_UPDATE_MUTATION,
  USER_DELETE_MUTATION,
} from "graphql/mutations/users";
import { Auth } from "utils/auth";

import { GQLUser } from "models/user";
import { GQLRole, ROLES } from "models/generic";

interface CheckInConfigProps {
  data: GQLUser[];
  roles: GQLRole[];
  refetch: any;
}

export const Users: React.FC<CheckInConfigProps> = ({
  data,
  roles,
  refetch,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const upserted = (response: any) => {
    if (response && response.user && response.user.id) {
      setError(false);
      if (editId) {
        setMessage("The user has been updated!");
      } else {
        setMessage("The user has been created!");
      }

      refetch();
      setEditId(null);
    } else {
      setError(true);
      if (editId) {
        setMessage("There was an error updating the user!");
      } else {
        setMessage("There was an error creating the user!");
      }
    }
    setName("");
    setEmail("");
    setPassword("");
    setUsername("");
    setRole(null);
    setShowAdd(false);
  };

  const deleted = (response: any) => {
    if (response && response.user && response.user.id) {
      setError(false);
      setMessage("The user has been deleted!");
      refetch();
    } else {
      setError(true);
      setMessage("There was an error removing the user!");
    }
    setEditId(null);
  };

  const deleteColumn = (rowData: any) => {
    return (
      <Mutation
        mutation={USER_DELETE_MUTATION}
        variables={{
          checkInConfigId: rowData.id,
        }}
        onCompleted={({ deleteUser }: any) => {
          deleted(deleteUser);
        }}
      >
        {(mutation: any) => (
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => {
              mutation();
            }}
          />
        )}
      </Mutation>
    );
  };

  const editColumn = (rowData: any) => {
    return (
      <Button
        type="button"
        icon="pi pi-pencil"
        className="p-button-secondary"
        onClick={() => {
          setEditId(rowData.id);
          setName(rowData.name);
          setEmail(rowData.email);
          setPassword("");
          setUsername(rowData.username);
          setRole(rowData.role);
          setShowAdd(true);
        }}
      ></Button>
    );
  };

  const addFooter = () => {
    return (
      <Mutation
        mutation={
          editId
            ? password.length
              ? USER_PASSWORD_UPDATE_MUTATION
              : USER_UPDATE_MUTATION
            : USER_CREATE_MUTATION
        }
        variables={{
          userId: editId,
          clientId,
          clientUid,
          name,
          email,
          username,
          password,
          role,
        }}
        onCompleted={({ createUser, updateUser }: any) => {
          upserted(createUser || updateUser);
        }}
      >
        {(mutation: any) => (
          <Button
            label={editId ? "Update" : "Create"}
            icon="pi pi-pencil"
            onClick={() => mutation()}
          />
        )}
      </Mutation>
    );
  };

  return (
    <>
      <div className="p-col-12 p-md-4">
        <Button
          label="Create"
          icon="pi pi-external-link"
          onClick={() => {
            setEditId(null);
            setShowAdd(true);
          }}
        />
      </div>
      {message.length ? (
        <div
          className={classnames(
            "p-messages",
            "p-component",
            "p-col-12",
            error ? "p-messages-error" : "p-messages-success"
          )}
        >
          <div className="p-messages-wrapper">
            <ul>
              <li>
                <span className="p-messages-detail">{message}</span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      <Dialog
        header={editId ? "Edit" : "Add"}
        visible={showAdd}
        footer={addFooter()}
        onHide={() => {
          setEditId(null);
          setShowAdd(false);
        }}
      >
        <div className="p-grid" style={{ maxWidth: "400px" }}>
          <div className="p-col-12">Name:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Name"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className="p-col-12">Email:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
          <div className="p-col-12">Username:</div>
          <div className="p-col-12">
            <InputText
              placeholder="Username"
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
            />
          </div>
          <div className="p-col-12">Password:</div>
          <div className="p-col-12">
            <Password
              placeholder="**********"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </div>
          <div className="p-col-12">Role:</div>
          <div className="p-col-12">
            <Dropdown
              value={role}
              options={roles
                .filter((role: GQLRole) => ROLES.includes(role.name))
                .map((role: GQLRole) => ({
                  value: role.id,
                  label: role.name,
                }))}
              onChange={(e) => {
                setRole(e.value);
              }}
              placeholder="Select a role"
            />
          </div>
        </div>
      </Dialog>
      <div className="card card-w-title">
        <h1>Users</h1>
        <DataTable
          value={data}
          header="Users"
          style={{ textAlign: "center" }}
          paginator={true}
          rows={10}
        >
          <Column field="name" header="Name" />
          <Column field="email" header="Email" />
          <Column field="username" header="Username" />
          <Column field="role.name" header="Role" />
          <Column field="id" header="Edit" body={editColumn} />
          <Column field="id" header="Delete" body={deleteColumn} />
        </DataTable>
      </div>
    </>
  );
};
