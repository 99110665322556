import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLUser } from "models/user";

import MY_ACCOUNT_QUERY from "graphql/queries/users/myAccount";
import { Auth } from "utils/auth";
import { MyAccount } from "components/MyAccount";

const MyAccountContainer = () => {
  const userId = Auth.getUserId();
  return (
    <Query query={MY_ACCOUNT_QUERY} userId={userId}>
      {({ data: { users }, refetch }: QueryResponse<GQLUser>) => {
        return <MyAccount data={users[0]} refetch={refetch} />;
      }}
    </Query>
  );
};

export default MyAccountContainer;
