import gql from "graphql-tag";

export const SECTIONS_QUERY = gql`
  query Sections($clientUid: String!) {
    sections(where: { clientUid: $clientUid }) {
      id
      name
      public
      categories {
        id
        name
      }
      image {
        url
      }
      visible
      order
      slider
    }
  }
`;
