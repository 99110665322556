import { GQLFile } from "./generic";

export const CHECK_IN_FIELD_TYPES = [
  { value: "text", label: "Text (Read Only)" },
  { value: "file", label: "File" },
  { value: "value", label: "Value" },
  { value: "date", label: "Date" },
  { value: "signature", label: "Signature" },
];

export interface GQLCheckInConfig {
  id: string;
  name: string;
  label: string;
  description: string;
  required: boolean;
  type: string;
  order: string;
}

export interface GQLCheckInData {
  user: string;
  clientUid: string;
  checkInConfig: string;
  value: boolean;
  file: GQLFile;
}
