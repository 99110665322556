import React from "react";

import SettingsContainer from "containers/Settings";

export const PageSettings: React.FC = () => {
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>Settings Setup</h1>
            <SettingsContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
