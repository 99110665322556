import React from "react";

import Query from "components/Query";
import { QueryResponse } from "models/query";
import { GQLSection } from "models/sections";

import { SECTIONS_QUERY } from "graphql/queries/sections/sections";
import { Auth } from "utils/auth";
import { Sections } from "components/Sections";

const SectionsContainer = () => {
  const clientUid = Auth.getClientUid();
  return (
    <div>
      <Query query={SECTIONS_QUERY} clientUid={clientUid}>
        {({ data: { sections }, refetch }: QueryResponse<GQLSection>) => {
          return <Sections data={sections} refetch={refetch} />;
        }}
      </Query>
    </div>
  );
};

export default SectionsContainer;
