import gql from "graphql-tag";

export const DASHBOARD_QUERY = gql`
  query Dashboard($clientUid: String!) {
    totalCurrentGuests: guestsConnection(
      where: { clientUid: $clientUid, checkedIn: true }
    ) {
      aggregate {
        count
      }
    }

    totalGuests: guestsConnection(where: { clientUid: $clientUid }) {
      aggregate {
        count
      }
    }

    totalOrders: ordersConnection(where: { clientUid: $clientUid }) {
      aggregate {
        count
      }
    }

    totalCompletedOrders: ordersConnection(
      where: { clientUid: $clientUid, status: "completed" }
    ) {
      aggregate {
        count
      }
    }

    totalPendingOrders: ordersConnection(
      where: { clientUid: $clientUid, status: "pending" }
    ) {
      aggregate {
        count
      }
    }

    totalCanceledOrders: ordersConnection(
      where: { clientUid: $clientUid, status: "canceled" }
    ) {
      aggregate {
        count
      }
    }

    pendingOrders: orders(where: { clientUid: $clientUid, status: "pending" }) {
      id
      created
      lastUpdate
      guest {
        room {
          name
        }
        user {
          name
          email
        }
      }
    }

    employees: users(
      where: { clientUid: $clientUid, role: { name: "Employee" } }
    ) {
      id
      name
      email
      role {
        name
      }
    }

    admins: users(where: { clientUid: $clientUid, role: { name: "Admin" } }) {
      id
      name
      email
      role {
        name
      }
    }

    maintainers: users(
      where: { clientUid: $clientUid, role: { name: "Maintainer" } }
    ) {
      id
      name
      email
      role {
        name
      }
    }
  }
`;
