import gql from "graphql-tag";

export const SECTION_CREATE_MUTATION = gql`
  mutation CreateSection(
    $clientId: ID!
    $clientUid: String!
    $name: String
    $isVisible: Boolean!
    $slider: Boolean!
    $order: Int
  ) {
    createSection(
      input: {
        data: {
          name: $name
          client: $clientId
          clientUid: $clientUid
          public: false
          visible: $isVisible
          slider: $slider
          order: $order
        }
      }
    ) {
      section {
        id
      }
    }
  }
`;

export const SECTION_UPDATE_MUTATION = gql`
  mutation UpdateSection(
    $sectionId: ID!
    $name: String
    $isVisible: Boolean!
    $slider: Boolean!
    $order: Int
  ) {
    updateSection(
      input: {
        where: { id: $sectionId }
        data: {
          name: $name
          visible: $isVisible
          slider: $slider
          order: $order
        }
      }
    ) {
      section {
        id
      }
    }
  }
`;

export const SECTION_DELETE_MUTATION = gql`
  mutation DeleteSection($sectionId: ID!) {
    deleteSection(input: { where: { id: $sectionId } }) {
      section {
        id
      }
    }
  }
`;
