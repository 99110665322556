import gql from "graphql-tag";

export const GUEST_CREATE_MUTATION = gql`
  mutation CreateGuest(
    $clientId: ID!
    $clientUid: String!
    $checkIn: Date
    $checkOut: Date
    $roomId: ID
    $userId: ID
  ) {
    createGuest(
      input: {
        data: {
          client: $clientId
          clientUid: $clientUid
          checkIn: $checkIn
          checkOut: $checkOut
          room: $roomId
          user: $userId
          active: true
        }
      }
    ) {
      guest {
        id
      }
    }
  }
`;

export const GUEST_UPDATE_MUTATION = gql`
  mutation UpdateGuest(
    $guestId: ID!
    $checkIn: Date
    $checkOut: Date
    $roomId: ID
    $isActive: Boolean
  ) {
    updateGuest(
      input: {
        where: { id: $guestId }
        data: {
          checkIn: $checkIn
          checkOut: $checkOut
          room: $roomId
          active: $isActive
        }
      }
    ) {
      guest {
        id
      }
    }
  }
`;

export const GUEST_DELETE_MUTATION = gql`
  mutation DeleteGuest($guestId: ID!) {
    deleteGuest(input: { where: { id: $guestId } }) {
      guest {
        id
      }
    }
  }
`;
