import gql from "graphql-tag";

export const CHECK_IN_CONFIG_CREATE_MUTATION = gql`
  mutation CreateCheckInConfig(
    $clientId: ID!
    $clientUid: String!
    $name: String!
    $label: String
    $description: String
    $type: String
    $isRequired: Boolean
    $order: Int
  ) {
    createCheckInConfig(
      input: {
        data: {
          client: $clientId
          clientUid: $clientUid
          name: $name
          label: $label
          description: $description
          type: $type
          required: $isRequired
          order: $order
        }
      }
    ) {
      checkInConfig {
        id
      }
    }
  }
`;

export const CHECK_IN_CONFIG_UPDATE_MUTATION = gql`
  mutation UpdateCheckInConfig(
    $checkInConfigId: ID!
    $name: String!
    $label: String
    $description: String
    $type: String
    $isRequired: Boolean
    $order: Int
  ) {
    updateCheckInConfig(
      input: {
        where: { id: $checkInConfigId }
        data: {
          name: $name
          label: $label
          description: $description
          type: $type
          required: $isRequired
          order: $order
        }
      }
    ) {
      checkInConfig {
        id
      }
    }
  }
`;

export const CHECK_IN_CONFIG_DELETE_MUTATION = gql`
  mutation DeleteCheckInConfig($checkInConfigId: ID!) {
    deleteCheckInConfig(input: { where: { id: $checkInConfigId } }) {
      checkInConfig {
        id
      }
    }
  }
`;
