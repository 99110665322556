import gql from "graphql-tag";

export const MY_ACCOUNT_PASSWORD_UPDATE_MUTATION = gql`
  mutation UpdateMyAccount(
    $userId: ID!
    $name: String
    $username: String
    $email: String
    $password: String
  ) {
    updateUser(
      input: {
        where: { id: $userId }
        data: {
          name: $name
          username: $username
          email: $email
          password: $password
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const MY_ACCOUNT_UPDATE_MUTATION = gql`
  mutation UpdateMyAccount(
    $userId: ID!
    $name: String
    $username: String
    $email: String
  ) {
    updateUser(
      input: {
        where: { id: $userId }
        data: { name: $name, username: $username, email: $email }
      }
    ) {
      user {
        id
      }
    }
  }
`;
