import gql from "graphql-tag";

export const SERVICES_CREATE_MUTATION = gql`
  mutation CreateService(
    $clientId: ID!
    $clientUid: String!
    $label: String!
    $description: String
    $price: String
    $type: ENUM_SERVICES_TYPE
    $order: Int
  ) {
    createService(
      input: {
        data: {
          client: $clientId
          clientUid: $clientUid
          label: $label
          description: $description
          price: $price
          type: $type
          order: $order
        }
      }
    ) {
      service {
        id
      }
    }
  }
`;

export const SERVICES_UPDATE_MUTATION = gql`
  mutation UpdateService(
    $serviceId: ID!
    $label: String!
    $description: String
    $price: String
    $type: ENUM_SERVICES_TYPE
    $order: Int
  ) {
    updateService(
      input: {
        where: { id: $serviceId }
        data: {
          label: $label
          description: $description
          price: $price
          type: $type
          order: $order
        }
      }
    ) {
      service {
        id
      }
    }
  }
`;

export const SERVICES_DELETE_MUTATION = gql`
  mutation DeleteService($serviceId: ID!) {
    deleteService(input: { where: { id: $serviceId } }) {
      service {
        id
      }
    }
  }
`;
