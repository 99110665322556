import React, { useState } from "react";
import { GQLSettings } from "models/settings";
import { Mutation } from "react-apollo";
import classnames from "classnames";

import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";

import {
  SETTINGS_CREATE_MUTATION,
  SETTINGS_UPDATE_MUTATION,
} from "graphql/mutations/settings";
import { Auth } from "utils/auth";

interface SettingsProps {
  data: GQLSettings;
  refetch: any;
}

export const Settings: React.FC<SettingsProps> = ({ data, refetch }) => {
  const {
    id: originalId,
    checkIn: originalCheckIn,
    services: originalServices,
    roomService: originalRoomService,
  } = data || ({} as GQLSettings);

  const [settingsId, setSettingsId] = useState(originalId || null);
  const [checkIn, setCheckIn] = useState(originalCheckIn || false);
  const [services, setServices] = useState(originalServices || true);
  const [roomService, setRoomService] = useState(originalRoomService || false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const clientId = Auth.getClientId();
  const clientUid = Auth.getClientUid();

  const updated = (status: any) => {
    if (status && status.setting && status.setting.id) {
      setSettingsId(status.setting.id);
      setMessage("The settings has been updated!");
      setError(false);
      refetch();
    } else {
      setError(true);
      setMessage("There was an error updating your settings!");
    }
  };

  const onChange = (setFunction: any) => (e: any) => {
    setFunction(e.value);
  };

  return (
    <>
      <div className="p-grid">
        {message.length ? (
          <div
            className={classnames(
              "p-messages",
              "p-component",
              "p-col-12",
              error ? "p-messages-error" : "p-messages-success"
            )}
          >
            <div className="p-messages-wrapper">
              <ul>
                <li>
                  <span className="p-messages-detail">{message}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <h3 className="p-col-12">Client Modules</h3>
        <div className="p-col-12 p-md-2">
          <div>Check In</div>
          <ToggleButton checked={checkIn} onChange={onChange(setCheckIn)} />
        </div>
        <div className="p-col-12 p-md-2">
          <div>Services</div>
          <ToggleButton checked={services} onChange={onChange(setServices)} />
        </div>
        <div className="p-col-12 p-md-2">
          <div>Room Service</div>
          <ToggleButton
            checked={roomService}
            onChange={onChange(setRoomService)}
          />
        </div>
        <div className="p-col-12">
          <div className="p-col-3">
            <Mutation
              mutation={
                settingsId ? SETTINGS_UPDATE_MUTATION : SETTINGS_CREATE_MUTATION
              }
              variables={{
                clientId,
                clientUid,
                settingsId,
                checkIn,
                services,
                roomService,
              }}
              onCompleted={({ createSetting, updateSetting }: any) =>
                updated(createSetting || updateSetting)
              }
            >
              {(mutation: any) => (
                <Button
                  label="Save"
                  icon="pi pi-pencil"
                  onClick={() => {
                    mutation();
                  }}
                />
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </>
  );
};
