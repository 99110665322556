import React from "react";

import Query from "components/Query";
import ROOMS_QUERY from "../../graphql/queries/room/rooms";
import { QueryResponse } from "../../models/query";
import { GQLRoom } from "../../models/room";
import { Auth } from "utils/auth";

interface RoomsContainerProps {
  Component: React.FC<any>;
}

const RoomsContainer = ({ Component }: RoomsContainerProps) => {
  const clientUid = Auth.getClientUid();
  return (
    <div>
      <Query query={ROOMS_QUERY} clientUid={clientUid}>
        {({ data: { rooms }, refetch }: QueryResponse<GQLRoom>) => {
          return <Component data={rooms} refetch={refetch} />;
        }}
      </Query>
    </div>
  );
};

export default RoomsContainer;
