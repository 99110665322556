import { GQLGuest } from "./guest";
import { GQLRoom } from "./room";

export interface GQLOrder {
  id: string;
  status: string;
  guest: GQLGuest;
  room: GQLRoom;
  lastUpdate: string;
  created: string;
  type: string;
  idType: string;
}

export const ORDER_STATUSES = [
  { value: "accepted", label: "Accepted" },
  { value: "pending", label: "Pending" },
  { value: "completed", label: "Completed" },
  { value: "canceled", label: "Canceled" },
  { value: "rejected", label: "Rejected" },
];
