import React from "react";

import MyAccountContainer from "containers/MyAccount";

export const PageMyAccount: React.FC = () => {
  return (
    <div className="p-fluid">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>My Account</h1>
            <MyAccountContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
